






















































































































































































































































































































































































































































































































































































































































































































































































































import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { userViews, FALSY, INPUT_TYPES, MODULE_STRINGS, OFFER_TYPE_DEFAULT_COPY_MAP, menuStyles, MILESTONE_NAMES, UTM_EVENT_NAMES } from '@/constants'
import WorkflowStatus from '@/components/hotel-dashboard/workflow-manager/WorkflowStatus.vue'
import Modal from '@/components/modal/Modal.vue'
import Textfield from '@/components/textfield/Textfield.vue'
import { cloneDeep } from 'lodash'
import { CampaignEventConfig, Workflow, NodeTemplate, Resp, Campaign, ErrorObject, NodeOperator, EmailDesign, UTMEventName, EventName, WorkflowOfferType } from '@/types'
import Select from '@/components/select/Select.vue'
import EmailDesignTableEmbed from '@/components/hotel-dashboard/workflow-manager/EmailDesignTableEmbed.vue'
import CampaignSettings from '@/components/hotel-dashboard/campaign-manager/CampaignSettings.vue'
import EmailBuilder from '@/components/hotel-dashboard/email-design-manager/wizard/EmailBuilder.vue'
import Button from '@/components/button/Button.vue'
import RadioButton from '@/components/radio-button/RadioButton.vue'
import DagContainer from '@/components/hotel-dashboard/workflow-manager/dag/DagContainer.vue'
import AlgoTableEmbed from '@/components/hotel-dashboard/workflow-manager/AlgoTableEmbed.vue'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import moment from 'moment-timezone'
import { getTimeZone } from '@/utils/dates'
import { capitalizeAllWords, titleCase } from '@/lib/filters'
import { isBookingMilestone, isRoomNightsMilestone, isLifetimeValueMilestone, isUniqueHotelStays } from '@/utils/dag'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import { createFileUrl, getFile } from '@/utils/files'
import { getMetricsRoute } from '@/utils/workflows'

const { ECM } = MODULE_STRINGS
// todo: in later ticket going to replace all vuex module strings args to map functions
const EDM = 'edm'
const MESSAGES = 'messages'
const SELECT_EMAIL = 'select-email'
const EDIT_EMAIL = 'edit-email'
const HEADER_HEIGHT = 72

export type SendEmailModalStyle = {
  height: string;
  width: string;
  overflowX: string;
  overflowY: string;
  transition: string;
}

export type SaveArgs = {
  active: boolean;
  showSuccess: boolean;
  useActiveNode: boolean;
}

export type CreateUpdateArgs = SaveArgs & {
  campaignName: string;
}

// do not add `closeAllModals`
const MODAL_METHODS = {
  setShowDelayModal: 'setShowDelayModal',
  setShowOfferModal: 'setShowOfferModal',
  setShowSendEmailModal: 'setShowSendEmailModal',
  setShowNameModal: 'setShowNameModal',
  setShowStatusModal: 'setShowStatusModal',
  setShowStepModal: 'setShowStepModal',
  setShowTriggerModal: 'setShowTriggerModal'
}

const NEW_NODE: NodeTemplate = {
  children: [],
  parent: undefined,
  ctaLink: undefined,
  ctaRewardNotBeforeTime: undefined,
  ctaRewardExpirationTime: undefined,
  campaignId: undefined,
  param: '',
  rewardAlgoId: undefined,
  state: 'dr',
  emailDesignId: undefined,
  triggerTypeId: undefined,
  eventType: undefined,
  displayName: undefined,
  isPromotion: undefined
}

const ALLOWED_TRIGGER_TYPE_CATEGORIES: string[] = [
  'Booking Event',
  'Profile Event',
  'Scheduled Event',
  'Milestone Event',
  'Workflow Event',
]

export default {
  name: 'WorkflowManagerContainer',
  inject: [
    'Dashboard_scrollToContentBottom',
    'Dashboard_getContentWidth',
    'Dashboard_getContentHeight',
  ],
  components: {
    Dropdown,
    WorkflowStatus,
    Modal,
    Textfield,
    RadioButton,
    Select,
    EmailDesignTableEmbed,
    CampaignSettings,
    EmailBuilder,
    Button,
    DagContainer,
    AlgoTableEmbed,
    Datetime,
  },
  props: {
    editing: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      menuStyles,
      HEADER_HEIGHT,
      INPUT_TYPES,
      SELECT_EMAIL,
      EDIT_EMAIL,
      MODAL_METHODS,
      isLifetimeValueMilestone,
      forceWorkflowTest: false,
      testShowTextfield: false,
      addSplitPath: false,
      campaignErrors: false,
      nodeIsBeingHovered: false,
      newCampaignName: undefined,
      show: false,
      sendEmailModalStep: 'select-email',
      sendEmailDesignId: undefined,
      showDelayModal: false,
      showNameModal: false,
      showSendEmailModal: false,
      showStepModal: false,
      showTriggerModal: false,
      showStatusModal: false,
      showCheckStatusModal: false,
      showRemoveNodeModal: false,
      showOfferModal: false,
      // TODO: replace me with imported constants
      AR: 'auto-release',
      AS: 'auto-select',
      showMilestoneImageUploaderModal: false,
      copy: {
        modals: {
          triggerModal: {
            milestones: {
              tooltip: 'Note: This workflow will trigger the <b>first time</b> a customer reaches or exceeds the configured metric, and only while this workflow is live. We calculate the customers metric based on their entire booking history.',
              info: {
                totalBookings: 'At how many bookings should this workflow be triggered?',
                totalRoomNights: ' At how many total room nights should this workflow be triggered?',
                lifetimeValue: 'At what lifetime value (USD) should this workflow be triggered?',
                uniqueHotelStays: 'At how many unique hotel stays should this workflow be triggered?',

              },
              placeholders: {
                totalBookings: 'Enter number of bookings...',
                totalRoomNights: 'Enter number of nights...',
                lifetimeValue: 'Enter a value...',
                uniqueHotelStays: 'Enter number of unique hotel stays…',
              }
            }
          },
          offerModal: {
            placeholder: 'Add your offer title here',
            autoReleaseLabel: '<b>Auto-release</b> <span class="text-gray-400">is an offer where the customer makes a reward selection from a set determined by the selected reward algorithm. Once the customer selects a reward, it will be instantly redeemable.</span>',
            autoSelectLabel: '<b>Auto-select</b> <span class="text-gray-400">is an offer where we automatically select rewards for the customer. The rewards are instantly redeemable.</span>',
            offerTypeCopy: 'Is this special offer <b>auto-release</b> or <b>auto-select</b>?',
            milestoneTooltip: 'A milestone is a specific type of offer which will be shown on the “Milestones” page of your loyalty portal. [Need product team help with defining milestone offer]. Please reach out to your CSM if you have any questions about milestones.',
            tableHeader: 'Which advanced algorithm will be used to offer the reward?',
            promotionBody: 'What do you want to call this offer? This name will be used to identify this offer in your reports.',
            milestoneBody: 'What do you want to call this offer? This will be the guest facing offer name.',
            milestoneButtonCopy: 'Next',
            bookingButtonCopy: 'Update Offer',
            promoButtonCopy: 'Update Offer',
            imageUploaderBody: 'Will this offer use a <b>custom reward image</b>',
            imageUploaderBody2: '? Optional.',
            imageUploaderBody3: 'Must be PNG or JPEG. No larger than 5MB.',
            imageUploaderTooltip: 'You may customize the visual of your milestone reward offer in the loyalty portal by uploading an image. The image will be cropped to the dimensions 221px (h) by 360px (w).',
            uploadImage: 'Upload Image',
            previous: 'Previous',
            uploadSizeErr: 'Please upload an jpeg or png image less than 5MB in size.',
          },
          removeNodeModal: {
            title: '*** WARNING ***',
            body: 'Deleting this will also delete the related historical email activity. Instead, deactivate this workflow and create a new workflow to preserve the historical reporting.',
            cancel: 'Cancel',
            submit: 'Delete'
          },
          statusModal: {
            placeholder: 'Choose a criteria...',
            UTMplaceholder: 'Enter UTM value...',
            workflowEventPlaceholder: 'Enter Workflow Event value...',
            UTMHelperText: 'The UTM value\'s name',
            workflowEventHelperText: 'The name associated with the workflow event.',
            title: 'Edit Status',
            body1: 'If the person matches the following criteria, allow them to continue down the workflow path.',
            cancel: null,
            submit: 'Update Status',
            addSplitPath: 'Split Path',
          },
          sendEmail: {
            selectAnother: 'Select Another Email',
            title: 'Select Email',
          },
          delayModal: {
            placeholder: 'Enter days',
            title: 'Edit Delay',
            body: 'Wait for...',
            days: 'days',
            cancel: null,
            submit: 'Update Delay',
            error: 'Must enter delay value.',
          },
          nameModal: {
            placeholder: 'Workflow 1',
            title: 'Edit Workflow Name',
            body: null,
            cancel: null,
            submit: 'Update Name'
          },
          stepModal: {
            title: 'What kind of step do you want to add?',
            step1: {
              title: 'Send Email',
              subtitle: 'Send a one-off email',
            },
            step2: {
              title: 'Delay',
              subtitle: 'Wait for a period of time before continuing down the path',
            },
            step3: {
              title: 'Check Status',
              subtitle: 'Send people down the path based on a selected criteria',
            },
            step4: {
              title: 'Create Offer',
              subtitle: 'Offer guests a reward based on a filter',
            },
            step5: {
              title: 'Run Test',
              subtitle: 'Add a 50/50 split to test the next step in the workflow',
            },
          },
          checkStatusModal: {
            placeholder: {
              workflow: 'Enter Workflow Event value...',
              booking: 'Enter Booking Channel...',
              rate: 'Enter Rate Type...',
              utm: 'Enter UTM value...'
            }
          }
        },
        namePlaceholder: 'Name of Workflow',
        buttons: {
          save: 'Save'
        }
      },
      originalCampaign: undefined as Workflow | void,
      // used to filter trigger dropdown
      triggerTypeFilterCategory: undefined as string | void,
      /**
       * keep the dag container at a height that does not cause overflow-y for #content.
       * we want the content to overflow inside of dag container to so overflow-x scroll bar
       * is always visible to user. upate this value on mount and when there are resizes.
       */
      dagContainerHeight: undefined as string | void,
      resizeListener: undefined as (e: Event) => void,
      timeoutId: undefined as number | void,
      refLabels: {
        dagContainer: 'dag-container'
      } as Record<string, string>,
      userTimezone: moment.tz.guess(), // ie 'America/New_York' - required for datetime
      timezone: getTimeZone() // string rendered below datetime
    }
  },
  computed: {
    ...mapState([
      'drawerExpandedState'
    ]),
    ...mapState('edm', ['emailDesign']),
    ...mapState(ECM, [
      'campaign',
      'dag',
      'activeNode',
      'triggerTypes',
      'customMilestoneImage'
    ]),
    ...mapGetters('edm', [
      'GET_EMAIL_DESIGN',
    ]),
    ...mapGetters(ECM, [
      'GET_CAMPAIGN',
      'TRIGGER_TYPE_CATEGORIES',
      'TRIGGER_TYPES_LIST',
      'AUTO_RELEASE_ID',
      'AUTO_SELECT_ID',
      'AUTO_TRIGGER_IDS',
      'ACTIVE_TRIGGER_TYPE',
      'SEND_EMAIL_ID',
      'BOOKING_CREATED_ID',
      'BOOKING_OFFER_ID',
      'DELAY_ID',
      'STATUS_ID_LIST',
      'STATUS_LIST',
      'GET_CAMPAIGN_EVENT_TYPE',
      'GET_CAMPAIGN_TRIGGER',
      'ACTIVE_NODE_PARENT',
      'SEGMENTS_LIST',
      'SCHEDULED_EVENT_IDS',
      'RUN_TEST_TRIGGER_TYPE_ID'
    ]),
    ...mapGetters('edm', [
      'GET_EMAIL_DESIGN'
    ]),
    showReleaseType (): boolean {
      return !(this.activeNode?.triggerTypeId === this.BOOKING_OFFER_ID)
    },
    offerTypeLabels (): Array<{ label: WorkflowOfferType; id: number; disabled?: boolean; tooltipCopy: string}> {
      // Booking offer option is disabled if the root node is not Booking Created
      const bookingDisabled = !(this.ACTIVE_TRIGGER_TYPE?.id === this.BOOKING_CREATED_ID)

      return [
        {
          label: WorkflowOfferType.MILESTONE,
          id: this.AUTO_RELEASE_ID,
          disabled: false,
          tooltipCopy: 'A milestone is shown on the Milestones page of your loyalty portal.<br/><br/>Please reach out to your CSM if you have any questions about milestones.'
        },
        {
          label: WorkflowOfferType.PROMOTION,
          id: this.AUTO_SELECT_ID,
          disabled: false,
          tooltipCopy: 'A promotion is shown on the Promotions page of your loyalty portal. It must be an auto-select offer.<br/><br/>Please reach out to your CSM if you have any questions about promotions.'
        },
        {
          label: WorkflowOfferType.BOOKING,
          id: this.BOOKING_OFFER_ID,
          disabled: bookingDisabled,
          tooltipCopy: bookingDisabled
            ? 'You can only create a booking offer when your trigger is Booking Created'
            : 'A booking offer is shown on the Stays page of your loyalty portal.<br/><br/><strong>How it works:</strong> The customer makes a reward selection from a set determined by the selected reward algorithm. The selected rewards will then be sent to the customer relative to their <strong>check-in date</strong>.<br/><br/>Please reach out to your CSM if you have any questions about booking offers.'
        }
      ]
    },
    customValueTextfieldPlaceholder (): string {
      const { placeholder } = this.copy.modals.checkStatusModal
      if (this.forceWorkflowTest || this.isWorkflowEventStatus(this.triggerTypeName)) {
        return placeholder.workflow
      } else if (this.isBookingChannel(this.triggerTypeName)) {
        return placeholder.booking
      } else if (this.isRateType(this.triggerTypeName)) {
        return placeholder.rate
      }
      return placeholder.utm
    },
    triggerTypeName (): boolean {
      return this.STATUS_LIST?.find((trigger: { id: number }) => trigger.id === this.triggerTypeId)?.name
    },
    milestoneImageLink (): boolean | string {
      if (!this.customMilestoneImage) return false
      if (this.customMilestoneImage.mobile_display_image) return this.customMilestoneImage.mobile_display_image

      return createFileUrl(this.customMilestoneImage)
    },
    offerModalButtonCopy (): string {
      if (this.showMilestoneImageUploaderModal) return this.copy.modals.offerModal.promoButtonCopy
      if (this.isBookingOffer(this.activeNode.triggerTypeId)) return this.copy.modals.offerModal.bookingButtonCopy
      return this.isPromotion ? this.copy.modals.offerModal.promoButtonCopy : this.copy.modals.offerModal.milestoneButtonCopy
    },
    isPromotion (): boolean {
      return Boolean(this.activeNode?.isPromotion)
    },
    offerBodyCopy (): string {
      if (this.showMilestoneImageUploaderModal) return '' // body and sub-body copy handled by slots
      return this.isPromotion
        ? this.copy.modals.offerModal.promotionBody
        : this.copy.modals.offerModal.milestoneBody
    },
    isMilestoneTrigger (): boolean {
      return isBookingMilestone(this.trigger?.name) || isRoomNightsMilestone(this.trigger?.name) || isLifetimeValueMilestone(this.trigger?.name) || isUniqueHotelStays(this.trigger?.name)
    },
    inputInfoCopy (): string {
      if (isBookingMilestone(this.trigger?.name)) {
        return this.copy.modals.triggerModal.milestones.info.totalBookings
      } else if (isRoomNightsMilestone(this.trigger?.name)) {
        return this.copy.modals.triggerModal.milestones.info.totalRoomNights
      } else if (isLifetimeValueMilestone(this.trigger?.name)) {
        return this.copy.modals.triggerModal.milestones.info.lifetimeValue
      } else if (isUniqueHotelStays(this.trigger?.name)) {
        return this.copy.modals.triggerModal.milestones.info.uniqueHotelStays
      } else {
        return ''
      }
    },
    inputPlaceholderCopy (): string {
      if (isBookingMilestone(this.trigger?.name)) {
        return this.copy.modals.triggerModal.milestones.placeholders.totalBookings
      } else if (isRoomNightsMilestone(this.trigger?.name)) {
        return this.copy.modals.triggerModal.milestones.placeholders.totalRoomNights
      } else if (isLifetimeValueMilestone(this.trigger?.name)) {
        return this.copy.modals.triggerModal.milestones.placeholders.lifetimeValue
      } else if (isUniqueHotelStays(this.trigger?.name)) {
        return this.copy.modals.triggerModal.milestones.placeholders.uniqueHotelStays
      } else {
        return ''
      }
    },
    isSelectingMilestoneTrigger (): boolean {
      return this.triggerTypeFilterCategory === 'Milestone Event'
    },
    showMilestoneTriggerSection (): boolean {
      return Object.values(MILESTONE_NAMES).includes(this.trigger?.name)
    },
    displayName: {
      get (): string {
        return this.activeNode.displayName
      },
      set (value: string): void {
        this.SET_ACTIVE_NODE_DISPLAY_NAME(value)
      }
    },
    textfieldValue: {
      get (): string {
        return this.activeNode?.param || ''
      },
      set (value: string): void {
        this.SET_ACTIVE_NODE_PARAM(value ?? '')
      }
    },
    disableOfferSubmit (): boolean {
      // Booking Created does not required a display name
      if (this.isBookingOffer(this.activeNode.triggerTypeId)) {
        return this.activeNode.rewardAlgoId === undefined
      }

      return FALSY.includes(this.activeNode.displayName) || this.activeNode.rewardAlgoId === undefined
    },
    showStatusCopyOptions  (): boolean {
      return !FALSY.includes(this.triggerTypeId)
    },
    activeEmailDesign (): EmailDesign | void {
      return this.GET_EMAIL_DESIGN({ id: this.activeNode?.emailDesignId })
    },
    activeEmailDesignName (): string {
      return this.activeEmailDesign?.name || ''
    },
    sendEmailModalStyle (): SendEmailModalStyle {
      return {
        height: '500px',
        width: '1200px',
        overflowX: 'scroll',
        overflowY: 'scroll',
        transition: 'height linear .2s'
      }
    },
    sendEmailModalSubmitButton (): string {
      return this.selectEmailStepActive ? 'Save Selection' : 'Update Email'
    },
    selectEmailStepActive (): boolean {
      return this.sendEmailModalStep === SELECT_EMAIL
    },
    /**
     * triggers are limited to booking or profile events
     */
    triggerTypeCategoryOpts (): Array<{ label: string; value: string }> {
      return (this.TRIGGER_TYPE_CATEGORIES as string[])
        .filter((category: string) => {
          return ALLOWED_TRIGGER_TYPE_CATEGORIES.includes(category)
        })
        .map((category: string) => {
          return {
            label: category,
            value: category
          }
        })
    },
    triggerTypeOpts (): Array<{ label: string; value: string }> {
      return this.triggerTypesList?.filter((triggerType: CampaignEventConfig) => {
        return triggerType.category === this.triggerTypeFilterCategory
      })
        .map((triggerType: CampaignEventConfig) => {
          return {
            label: triggerType.copy,
            value: triggerType.id
          }
        })
    },
    triggerTypeId: {
      get (): number | undefined {
        return this.activeNode?.triggerTypeId
      },
      set (value: number | string): void {
        this.SET_ACTIVE_NODE_TRIGGER_TYPE_ID(value)
      }
    },
    disableTriggerType (): boolean {
      return FALSY.includes(this.triggerTypeFilterCategory)
    },
    statusOptions (): Array<{ label: string; value: string }> | [] {
      if (!this.STATUS_LIST || !Array.isArray(this.STATUS_LIST)) return []

      return this.STATUS_LIST
        .map((triggerType: CampaignEventConfig) => {
          return {
            label: capitalizeAllWords(triggerType.logic),
            value: triggerType.id
          }
        })
    },
    delay: {
      get (): string | number {
        const valueWithDays: string | void = (this.activeNode as Workflow)?.param
        if (typeof valueWithDays === 'string') {
          const maybeInt = parseInt(valueWithDays.replace(' days', ''))
          return isNaN(maybeInt)
            ? ''
            : maybeInt
        }
        return ''
      },
      set (value: string): void {
        if (value === '') {
          this.SET_ACTIVE_NODE_PARAM('')
        } else {
          this.SET_ACTIVE_NODE_PARAM(value + ' days')
        }
      }
    },
    milestoneValue: {
      get (): string {
        return (this.activeNode as Workflow).param || ''
      },
      set (value: string): void {
        if (value === '') {
          this.SET_ACTIVE_NODE_PARAM('')
        } else {
          this.SET_ACTIVE_NODE_PARAM(value)
        }
      }
    },
    disableTriggerSubmit (): boolean {
      if (this.isMilestoneTrigger) {
        if (typeof this.delay === 'number' && this.delay > 0) {
          return false
        }
        return true
      }

      if (this.SCHEDULED_EVENT_IDS.includes(this.triggerTypeId)) {
        if (!this.datetime || this.segments.length === 0) {
          return true
        }
      }
      return !this.triggerTypeId
    },
    trigger (): CampaignEventConfig | void {
      return this.GET_CAMPAIGN_TRIGGER({ id: this.activeNode?.triggerTypeId })
    },
    triggerCopy (): Pick<CampaignEventConfig, 'copy' | 'copyForOperatorNot'> {
      return this.trigger || { copy: '', copyForOperatorNot: '' }
    },
    isSelectedTriggerCopy () {
      return (op: NodeOperator): boolean => {
        if (this.addSplitPath) return true
        return this.activeNode?.operator === op
      }
    },
    showToggle (): boolean {
      return !this.activeNode?.id
    },
    /**
     * @note how we determine if the node is part of a split path
     */
    hasSiblings (): boolean {
      return this.ACTIVE_NODE_PARENT?.children.length > 1
    },
    invalidUTMValue (): boolean {
      if (!this.showCustomValueTextfield) return false
      return !this.activeNode?.param
    },
    statusSubmitDisabled (): boolean {
      return this.hasSiblings || this.invalidUTMValue
    },
    disableStep (): boolean {
      // inserting between nodes
      return !FALSY.includes(this.activeNode.parent) &&
        this.activeNode.children.length > 0
    },
    showTriggerModalDatepicker (): boolean {
      return this.SCHEDULED_EVENT_IDS.includes(this.triggerTypeId)
    },
    /**
     * for Scheduled Event trigger nodes.
     * datetime is stored in UTC and presented in local timezone.
     * see <Datetime> instance in html block for prop configuration.
     */
    datetime: {
      get (): string | void {
        return this.activeNode.param
      },
      set (value: string): void {
        const date = !value
          ? null
          : moment.utc(value).format()
        this.SET_ACTIVE_NODE_PARAM(date)
      }
    },
    segments: {
      get (): number[] {
        return this.campaign.segmentIds
      },
      set (values: number[]): void {
        this.SET_SEGMENT_IDS(values)
      }
    },
    segmentOpts () {
      return this.SEGMENTS_LIST.map(segment => {
        return {
          ...segment,
          name: titleCase(segment.name)
        }
      })
    },
    /**
     * workflows does not support Send Immediately trigger
     */
    triggerTypesList (): Array<CampaignEventConfig> {
      return (this.TRIGGER_TYPES_LIST as Array<CampaignEventConfig>)?.filter(trigger => {
        return trigger.name !== 'Send Immediately'
      })
    },
    showCustomValueTextfield (): boolean {
      return ['isWorkflowEventStatus', 'isUTMStatus', 'isBookingChannel', 'isRateType']
        .some(check => this[check](this.triggerTypeName))
    },
    offerTypeConfigs (): Record<number, { promotion: boolean; copy: WorkflowOfferType }> {
      const configs = {
        [this.AUTO_SELECT_ID]: {
          promotion: this.activeNode?.isPromotion,
          copy: this.activeNode?.isPromotion ? WorkflowOfferType.PROMOTION : WorkflowOfferType.MILESTONE
        },
        [this.AUTO_RELEASE_ID]: {
          promotion: false,
          copy: WorkflowOfferType.MILESTONE
        },
        [this.BOOKING_OFFER_ID]: {
          promotion: false,
          copy: WorkflowOfferType.BOOKING
        }
      }

      return configs
    }
  },
  watch: {
    triggerTypeId (_, oldValue): void {
      /**
       * cleanup when changing trigger types. use FALSY because
       * oldValue could be a number.
       */
      if (!FALSY.includes(oldValue)) {
        this.SET_ACTIVE_NODE_PARAM('')
      }
    },
    triggerTypeFilterCategory (newValue: number | string | void): void {
      /**
       * when user toggles category dropdown and its different from the selected
       * trigger's category we will reset the value of the trigger to null
       */
      const triggerType = this.ACTIVE_TRIGGER_TYPE
      if (newValue !== triggerType?.category) {
        this.SET_ACTIVE_NODE_TRIGGER_TYPE_ID(null)
      }
      this.maybeSelectFirstOption()
    },
    showOfferModal (newValue: boolean) {
      if (newValue) {
        /**
         * sets default offer copy if there isn't one
         */
        if (!this.activeNode.displayName) {
          const displayName = this.defaultOfferCopy(
            this.GET_CAMPAIGN_TRIGGER({ id: this.campaign.dag.triggerTypeId })?.name
          )
          this.SET_ACTIVE_NODE_DISPLAY_NAME(displayName)
          return displayName
        }
      }
    },
  },
  beforeDestroy (): void {
    this.removeResizeListener()
  },
  async mounted (): Promise<void> {
    this.setupResizeListener()
    this.RESET_CAMPAIGN()
    this.RESET_DAG()
    await Promise.resolve([
      this.setup(),
      this.FETCH_SEGMENTS({ fetchAll: true })
    ])
    this.SET_CAMPAIGN_WORKFLOW_ENABLED(true)
    this.syncCampaignName()
    this.setDagContainerHeight()
    this.show = true
    this.handleTriggerModal()
  },
  async beforeRouteUpdate (to, from, next): Promise<void> {
    if (to.params.workflowId !== from.params.workflowId) {
      this.show = false
      this.RESET_CAMPAIGN()
      this.RESET_DAG()
      await Promise.resolve([
        this.setup(),
        this.FETCH_SEGMENTS({ fetchAll: true })
      ])
      this.SET_CAMPAIGN_WORKFLOW_ENABLED(true)
      this.syncCampaignName()
      this.setDagContainerHeight()
      this.show = true
      this.handleTriggerModal()
    }
    next()
  },
  methods: {
    ...mapActions(ECM, [
      'FETCH_CAMPAIGNS',
      'FETCH_TRIGGER_TYPES',
      'CREATE_OR_UPDATE_CAMPAIGN',
      'TOGGLE_CAMPAIGN_ACTIVE',
      'SETUP_DAG',
      'CREATE_OR_UPDATE_NODE',
      'ADD_NEWEST_NODE_IDS',
      'FETCH_SEGMENTS',
      'CREATE_OR_UPDATE_CUSTOM_MILESTONE_IMAGE',
      'FETCH_CUSTOM_MILESTONE_IMAGE',
      'DELETE_CUSTOM_MILESTONE_IMAGE'
    ]),
    ...mapActions(MESSAGES, [
      'ADD_ERROR',
    ]),
    ...mapActions(EDM, [
      'FETCH_EMAIL_DESIGNS',
      'FETCH_EMAIL_STRING_AND_MERGE',
      'CREATE_OR_UPDATE_EMAIL_DESIGN',
    ]),
    ...mapMutations(ECM, [
      'RESET_CAMPAIGN',
      'RESET_DAG',
      'SET_CAMPAIGN',
      'SET_CAMPAIGN_NAME',
      'SET_EMAIL_DESIGN_ID',
      'SET_ACTIVE_NODE',
      'RESET_ACTIVE_NODE',
      'SET_CAMPAIGN_WORKFLOW_ENABLED',
      'SET_ACTIVE_NODE_TRIGGER_TYPE_ID',
      'SET_ACTIVE_NODE_EMAIL_DESIGN_ID',
      'SET_ACTIVE_NODE_PARAM',
      'SET_ACTIVE_NODE_PARENT',
      'SET_ACTIVE_NODE_CTA_LINK',
      'SET_ACTIVE_NODE_DISPLAY_NAME',
      'SET_ACTIVE_NODE_ALGO_ID',
      'SET_ACTIVE_NODE_IS_PROMOTION',
      'SET_ACTIVE_NODE_OPERATOR',
      'SET_ACTIVE_NODE_ID',
      'SET_ACTIVE_NODE_UTM_VALUE',
      'REMOVE_NODE',
      'SET_SEGMENT_IDS',
      'SET_CUSTOM_MILESTONE_IMAGE',
      'RESET_CUSTOM_MILESTONE_IMAGE',
    ]),
    ...mapMutations(EDM, [
      'SET_EMAIL_DESIGN',
    ]),
    getLabel (): string {
      if (typeof this.activeNode.triggerTypeId === 'number') {
        return this.offerTypeConfigs[this.activeNode.triggerTypeId]?.copy || ''
      }
      return ''
    },
    isWorkflowEventStatus (eventName: UTMEventName | EventName): boolean {
      return eventName === 'Has Workflow Event'
    },
    isUTMStatus (): boolean {
      return UTM_EVENT_NAMES.includes(this.triggerTypeName)
    },
    isBookingChannel (eventName: UTMEventName | EventName): boolean {
      return eventName === 'Has Booking Channel'
    },
    isRateType (eventName: UTMEventName | EventName): boolean {
      return eventName === 'Has Rate Type'
    },
    removeCustomMilestoneImage (): void {
      if (this.editing && this.customMilestoneImage) this.DELETE_CUSTOM_MILESTONE_IMAGE({ id: this.activeNode.id })
      this.RESET_CUSTOM_MILESTONE_IMAGE()
    },
    updateCustomMilestoneImage (event: Event): void {
      const target = event.target as HTMLInputElement
      const file = getFile(target.files)
      if (file) {
        const fiveMB = 5 * 1024 * 1024
        if (file.size > fiveMB) {
          this.ADD_ERROR(new Error(this.copy.modals.offerModal.uploadSizeErr))
          return
        }
        this.SET_CUSTOM_MILESTONE_IMAGE(file)
      }
    },
    handleImageUploadPrevious (): void {
      this.showMilestoneImageUploaderModal = false
      this.showOfferModal = true
    },
    setOfferType (id: number): void {
      const config = this.offerTypeConfigs[id]
      if (config) {
        this.SET_ACTIVE_NODE_TRIGGER_TYPE_ID(id)
        this.SET_ACTIVE_NODE_IS_PROMOTION(config.promotion)
      }
    },
    defaultOfferCopy (campaignTriggerName: string): string {
      return OFFER_TYPE_DEFAULT_COPY_MAP[campaignTriggerName]
    },
    async handleEmailDesign (): Promise<void> {
      await this.fetchEmailDesign()
      await this.fetchHtmlString()
    },
    async fetchEmailDesign (): Promise<void> {
      if (!this.GET_EMAIL_DESIGN({ id: this.activeNode?.emailDesignId })) {
        await this.FETCH_EMAIL_DESIGNS({ id: this.activeNode.emailDesignId })
      }
    },
    async fetchHtmlString (): Promise<void> {
      if (!this.activeEmailDesign?.htmlUrl) {
        console.log('Active email design does not have a htmlUrl.')
        return
      }
      await this.FETCH_EMAIL_STRING_AND_MERGE({
        id: this.activeNode.emailDesignId,
        url: this.activeEmailDesign.htmlUrl
      })
    },
    navToCreateNewRewardAlgo (): void {
      this.$router.push({
        name: userViews.REWARD_ALGOS_WIZARD_NEW,
        params: {
          hotelId: this.$route.params.hotelId
        }
      })
    },
    scrollToMostRecentNode (id: number | void): void {
      if (typeof id !== 'number') return

      const el = document.querySelector(`#node-container-${id}`) as HTMLElement | void
      if (!el) return

      if (this.isOnEdge(el)) this.scrollToBottomOfDagContainer()
    },
    isOnEdge (el: HTMLElement): boolean {
      const rect = el.getBoundingClientRect()
      const position = el.offsetHeight + rect.top
      return position > window.innerHeight
    },
    scrollToBottomOfDagContainer (): void {
      const container = this.$refs[this.refLabels.dagContainer] as HTMLElement | void
      if (container) {
        container.scrollTop = container.offsetHeight
      }
    },
    setCampaignErrors (value: boolean): void {
      this.campaignErrors = value
    },
    ...mapActions(MESSAGES, [
      'ADD_ERROR',
    ]),
    filterNumericalInputValue (e: KeyboardEvent): void {
      // prevents exponent "e" and negative "-"
      if (e.code === 'KeyE' || e.code === 'Minus' || e.code === 'Period') {
        e.preventDefault()
      }
    },
    setSendEmailStep (step: string) {
      this.sendEmailModalStep = step
    },
    handleNameSubmit (): void {
      this.saveWorkflow({ active: (this.campaign as Campaign).active, showSuccess: true })
      this.setShowNameModal(false)
    },
    async createOrUpdate ({
      active,
      showSuccess,
      campaignName,
      useActiveNode = false
    }: CreateUpdateArgs): Promise<Resp> {
      const resp = await this.CREATE_OR_UPDATE_CAMPAIGN({
        active,
        showSuccess,
        campaignName,
        useActiveNode,
        id: this.$route.params.workflowId,
        workflow: true,
      })
      this.syncCampaignName()
      return resp
    },
    async saveWorkflow ({
      active,
      showSuccess,
      useActiveNode = false
    }: SaveArgs): Promise<Resp> {
      const resp = await this.createOrUpdate({
        active,
        showSuccess,
        campaignName: this.newCampaignName,
        useActiveNode,
      })

      if (!resp.error && !this.editing) {
        const campaignId = resp.data.data.id
        this.navToEditWorkflow(campaignId)
      }

      // stay in edit but update workflow in state
      if (!resp.error) {
        await this.setup()
      }

      return resp
    },
    navToEditWorkflow (campaignId: number): void {
      this.$router.replace({
        name: userViews.WORKFLOWS_EDIT,
        params: {
          hotelId: this.$route.params.hotelId,
          workflowId: campaignId
        },
      })
    },
    async setup (): Promise<void> {
      this.triggerTypeFilterCategory = null
      this.RESET_ACTIVE_NODE()

      if (this.triggerTypesList.length === 0) {
        this.FETCH_TRIGGER_TYPES()
      }

      if (this.editing) {
        // check if workflow is already in store. if not => fetch
        const { workflowId } = this.$route.params
        let workflow = await this.GET_CAMPAIGN({ id: workflowId })
        if (!workflow) {
          const resp = await this.FETCH_CAMPAIGNS({ id: workflowId, workflows: true })
          if (resp.error) {
            // if failing to fetch detail route should push back to table
            this.$router.push({ name: userViews.WORKFLOWS })
            return
          }
          workflow = this.GET_CAMPAIGN({ id: workflowId })
        }
        this.SET_CAMPAIGN(workflow)
        this.SETUP_DAG()
        this.syncTriggerTypeFilterCategory()
        this.setOriginalCampaign()
        this.handleEmailDesign()
      } else {
        this.SET_CAMPAIGN_NAME(this.getName())
      }
    },
    closeAllModals (): void {
      Object.values(MODAL_METHODS).forEach((method: string) => {
        const fn = this[method]
        if (typeof fn === 'function') fn(false)
      })
    },
    [MODAL_METHODS.setShowOfferModal] (value: boolean): void {
      if (value) this.closeAllModals()
      this.showOfferModal = value
    },
    [MODAL_METHODS.setShowDelayModal] (value: boolean): void {
      if (value) this.closeAllModals()
      this.showDelayModal = value
    },
    [MODAL_METHODS.setShowNameModal] (value: boolean): void {
      if (value) this.closeAllModals()
      this.showNameModal = value
    },
    [MODAL_METHODS.setShowSendEmailModal] (value: boolean): void {
      if (value) {
        this.closeAllModals()
      }
      this.showSendEmailModal = value
    },
    [MODAL_METHODS.setShowStatusModal] (value: boolean): void {
      if (value) this.closeAllModals()
      this.showStatusModal = value
    },
    [MODAL_METHODS.setShowStepModal] (value: boolean): void {
      if (value) this.closeAllModals()
      this.showStepModal = value
    },
    [MODAL_METHODS.setShowTriggerModal] (value: boolean): void {
      if (value) this.closeAllModals()
      this.showTriggerModal = value
    },
    async createNodeAndMaybeScroll (
      { showSuccess, isSplitPath = false }:
      { showSuccess: boolean; isSplitPath: boolean }
    ): Promise<Resp> {
      const createNodeResp = await this.CREATE_OR_UPDATE_NODE({ showSuccess, isSplitPath })

      /**
       * errors for creating nodes with our without milestone images are handled by respective actions
       *
       * a node is always created, as the node id is required by the milestone image payload
       *
       * if node creation fails, image request is not dispatched—if node creation is successful but
       * image upload fails, node is created but snackbar err msg appears and offer modal is kept open
       */
      if (createNodeResp.error) return
      if (this.customMilestoneImage) {
        const customMilestoneImgResp = await this.CREATE_OR_UPDATE_CUSTOM_MILESTONE_IMAGE({ id: createNodeResp.data.id })

        if (customMilestoneImgResp.error) return

        this.showOfferModal = false
        this.showMilestoneImageUploaderModal = false
        this.RESET_CUSTOM_MILESTONE_IMAGE()
      }

      this.ADD_NEWEST_NODE_IDS(createNodeResp.data.id)
      this.scrollToMostRecentNode(createNodeResp.data.id)

      return createNodeResp
    },
    async handleRunTestClick (): Promise<void> {
      if (this.disableStep) return
      // for both this remains the same
      this.SET_ACTIVE_NODE_TRIGGER_TYPE_ID(this.RUN_TEST_TRIGGER_TYPE_ID)
      this.SET_ACTIVE_NODE_PARAM('0.5')

      // left side node
      let resp = await this.createNodeAndMaybeScroll({ showSuccess: false, isSplitPath: true })
      if (resp.error) return

      // right side node
      this.SET_ACTIVE_NODE_ID(null)
      resp = await this.createNodeAndMaybeScroll({ showSuccess: true, isSplitPath: true })
      if (resp.error) return

      // cleanup
      this.setAddSplitPath(false)
      this.RESET_ACTIVE_NODE()
      this.setShowStepModal(false)
    },
    /**
     * will create a status check node with `no` operator.
     * if user is creating a split path it will also
     * add a corresponding sibling to the previous node with the `none`
     * operator.
     */
    async handleStatusSubmit (): Promise<void> {
      let resp = await this.createNodeAndMaybeScroll({ showSuccess: true, isSplitPath: this.addSplitPath })
      if (resp.error) {
        return
      }

      if (this.addSplitPath) {
        this.SET_ACTIVE_NODE_ID(null)
        this.SET_ACTIVE_NODE_OPERATOR('nt' as NodeOperator)
        resp = await this.createNodeAndMaybeScroll({ showSuccess: false, isSplitPath: true })
        if (resp.error) {
          return
        }
        this.setAddSplitPath(false)
      }

      this.RESET_ACTIVE_NODE()
      this.setShowStatusModal(false)
    },
    handleStatusCancel (): void {
      this.setAddSplitPath(false)
      this.RESET_ACTIVE_NODE()
      this.setShowStatusModal(false)
    },
    handleSendEmailCancel (): void {
      this.setSendEmailStep(SELECT_EMAIL)
      this.RESET_ACTIVE_NODE()
      this.setShowSendEmailModal(false)
    },
    handleDelayCancel (): void {
      this.RESET_ACTIVE_NODE()
      this.setShowDelayModal(false)
    },
    async handleDelaySubmit (): Promise<void> {
      if (FALSY.includes(this.delay)) {
        this.ADD_ERROR({ message: this.copy.modals.delayModal.error })
      } else {
        await this.createNodeAndMaybeScroll({ showSuccess: true })
      }

      this.RESET_ACTIVE_NODE()
      this.setShowDelayModal(false)
    },
    handleEmailDesignRelatedFields (): void {
      const originalNode: Workflow | void = this.dag[this.activeNode.id]

      if (!originalNode) return

      if (this.activeNode.emailDesignId === originalNode.emailDesignId) {
        // user is selecting original email template, sync activeNode
        // with original values from state.dag
        this.SET_ACTIVE_NODE_ALGO_ID(originalNode.rewardAlgoId)
        this.SET_ACTIVE_NODE_CTA_LINK(originalNode.ctaLink)
      } else {
        // email design is changing, clear fields
        this.resetEmailDesignRelatedFields()
      }
    },
    async handleSendEmailSubmit (): Promise<void> {
      if (this.selectEmailStepActive) {
        this.handleEmailDesignRelatedFields()
        this.setSendEmailStep(EDIT_EMAIL)
      } else {
        const errors = this.validateCampaignSettings()
        if (errors.length > 0) {
          return
        }
        const resp = await this.createNodeAndMaybeScroll({ showSuccess: true })
        if (!resp.error) {
          // close modal and clean up
          this.setShowSendEmailModal(false)
          this.setSendEmailStep(SELECT_EMAIL)
          this.RESET_ACTIVE_NODE()
        }
      }
    },
    /**
     * if workflow is new we will create the workflow and node
     * with one request to event-based campaigns endpoint.
     * if workflow exists we will just be creating the node
     * with event nodes endpoint.
     */
    async handleTriggerSubmit (): Promise<void> {
      try {
        let resp
        const errors = []
        if (this.editing) {
          // [{error, data}, ...]
          resp = await Promise.all([
            this.CREATE_OR_UPDATE_NODE({ showSuccess: true }),
            this.saveWorkflow({
              active: this.campaign.active,
              showSuccess: true,
              useActiveNode: true
            })
          ])
          resp.forEach(r => {
            if (r.error) errors.push(r.error)
          })
        } else {
        // preserve active value
          resp = await this.saveWorkflow({
            active: this.campaign.active,
            showSuccess: true,
            useActiveNode: true,
          })
          if (resp.error) errors.push(resp.error)
        }
        this.setShowTriggerModal(false)
        this.RESET_ACTIVE_NODE()

        // request errors handled in actions, do not need to push messages
        if (errors.length > 0) {
          this.handleTriggerTypeFilterCategory()
          this.navToWorkflowsTable()
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleTriggerCancel (): void {
      if (this.editing) {
        this.SET_ACTIVE_NODE_TRIGGER_TYPE_ID(this.originalCampaign.dag.triggerTypeId)
      } else {
        this.SET_ACTIVE_NODE_TRIGGER_TYPE_ID(null)
        this.$router.push({
          name: userViews.WORKFLOWS,
          params: this.$route.params,
        })
      }
      this.setShowTriggerModal(false)
      this.handleTriggerTypeFilterCategory()
    },
    setOriginalCampaign (): void {
      this.originalCampaign = cloneDeep(this.campaign)
    },
    /**
     * use when loading edit view to sync modal category dropdwn
     * with existing trigger type's category
     */
    syncTriggerTypeFilterCategory (): void {
      this.triggerTypeFilterCategory = this.ACTIVE_TRIGGER_TYPE?.category
    },
    handleTriggerTypeFilterCategory (): void {
      if (this.ACTIVE_TRIGGER_TYPE.category) {
        this.syncTriggerTypeFilterCategory()
      } else {
        this.triggerTypeFilterCategory = null
      }
    },
    handleTriggerModal (): void {
      if (!this.editing) {
        setTimeout(() => {
          this.RESET_ACTIVE_NODE()
          this.setShowTriggerModal(true)
        }, 500)
      }
    },
    getName (): string {
      return `Workflow - ${new Date(Date.now()).toUTCString().slice(5, 29)}`
    },
    navToWorkflowsTable (): void {
      this.$router.push({
        name: userViews.WORKFLOWS,
        params: {
          hotelId: this.$route.params.hotelId
        }
      })
    },
    addNode ({ parent, children }: { parent: number; children: number[] }): void {
      const node = { ...NEW_NODE }
      node.parent = parent
      node.children = children
      this.SET_ACTIVE_NODE(node)
    },
    handleAddNode ({ parent, children }: { parent: number; children: number[] }): void {
      this.addNode({ parent, children })
      this.setShowStepModal(true)
    },
    async handleEditNode ({ id }: { id: number }): Promise<void> {
      const node = this.dag[id]
      this.SET_ACTIVE_NODE(node)
      if (!node.parent) {
        // this is the root node in dag and considered the trigger
        this.setShowTriggerModal(true)
      } else if (this.isSendEmail(node.triggerTypeId)) {
        this.setSendEmailStep(EDIT_EMAIL)
        this.setShowSendEmailModal(true)
      } else if (this.isDelay(node.triggerTypeId)) {
        this.setShowDelayModal(true)
      } else if (this.isOffer(node.triggerTypeId)) {
        this.showMilestoneImageUploaderModal = false
        this.setShowOfferModal(true)
        if (!this.activeNode.isPromotion && !this.customMilestoneImage) {
          await this.FETCH_CUSTOM_MILESTONE_IMAGE({ id: this.activeNode.id })
        }
      } else if (this.isStatus(node.triggerTypeId)) {
        this.setShowStatusModal(true)
      } else {
        console.log('Could not determine campaign event type using id:', node.id)
      }
    },
    isSendEmail (id: number): boolean {
      return id === this.SEND_EMAIL_ID
    },
    isBookingOffer (id: number): boolean {
      return id === this.BOOKING_OFFER_ID
    },
    isOffer (id: number): boolean {
      return this.AUTO_TRIGGER_IDS.includes(id) || this.isBookingOffer(id)
    },
    isDelay (id: number): boolean {
      return id === this.DELAY_ID
    },
    isStatus (id: number): boolean {
      return this.STATUS_ID_LIST.includes(id)
    },
    handleSendEmailClick (): void{
      this.SET_ACTIVE_NODE_TRIGGER_TYPE_ID(this.SEND_EMAIL_ID)
      this.setShowSendEmailModal(true)
    },
    // todo: disallow negative numbers in days on delay modal
    handleDelayClick (): void {
      this.SET_ACTIVE_NODE_TRIGGER_TYPE_ID(this.DELAY_ID)
      this.setShowDelayModal(true)
    },
    handleOfferCancel (): void {
      this.SET_ACTIVE_NODE_IS_PROMOTION(false)
      this.showMilestoneImageUploaderModal = false
      this.RESET_CUSTOM_MILESTONE_IMAGE()
      this.RESET_ACTIVE_NODE()
      this.setShowOfferModal(false)
    },
    async handleOfferSubmit (): Promise<void> {
      if (this.showMilestoneImageUploaderModal || this.isPromotion || this.isBookingOffer(this.activeNode.triggerTypeId)) {
        const showSuccess = this.isPromotion || !this.customMilestoneImage
        const resp = await this.createNodeAndMaybeScroll({ showSuccess })

        if (!resp.error) {
          this.RESET_ACTIVE_NODE()
          this.setShowOfferModal(false)
        }
      } else {
        this.showMilestoneImageUploaderModal = true
      }
    },
    handleOfferClick (): void {
      this.setShowOfferModal(true)
    },
    async removeNode ({ node }: { node: Workflow }): Promise<void> {
      this.SET_ACTIVE_NODE(node)
      this.setShowRemoveNodeModal(true)
    },
    setEmailDesignId (id: number): void {
      if (this.activeNode) {
        this.SET_ACTIVE_NODE_EMAIL_DESIGN_ID(id)
      }
    },
    handleShowStepModalCancel () {
      this.RESET_ACTIVE_NODE()
      this.setShowStepModal(false)
    },
    syncCampaignName (): void {
      this.newCampaignName = (this.campaign as Campaign)?.name
    },
    setShowRemoveNodeModal (value: boolean): void {
      this.showRemoveNodeModal = value
    },
    async handleRemoveNodeSubmit (): Promise<void> {
      this.REMOVE_NODE({
        id: this.activeNode.id,
        deleteChildren: this.hasSiblings
      })

      // in the case of run test, we delete the sibling split path node if either is deleted
      if (this.activeNode.triggerTypeId === this.RUN_TEST_TRIGGER_TYPE_ID) {
        this.REMOVE_NODE({
          id: this.ACTIVE_NODE_PARENT?.children[0],
          deleteChildren: this.hasSiblings
        })
      }

      const resp = await this.CREATE_OR_UPDATE_CAMPAIGN({
        id: this.$route.params.workflowId,
        showSuccess: true,
        useActiveNode: false,
        workflow: true
      })
      if (resp.error) {
        return
      }
      this.RESET_ACTIVE_NODE()
      this.setShowRemoveNodeModal(false)
    },
    handleRemoveNodeCancel (): void {
      this.RESET_ACTIVE_NODE()
      this.setShowRemoveNodeModal(false)
    },
    validateCampaignSettings (): ErrorObject[] | [] {
      const campaignSettingsContainer = this.$refs['campaign-settings']
      if (!campaignSettingsContainer) return []

      try {
        return campaignSettingsContainer?.checkAndDisplayErrors()
      } catch (e) {
        console.log('[validateCampaignSettings] error', e)
        return []
      }
    },
    /**
     * if the user selects a new email design we need to reset the associated
    * fields on the node before entering the CampaignSettings/EmailBuilder view.
     */
    resetEmailDesignRelatedFields (): void {
      this.SET_ACTIVE_NODE_ALGO_ID(null)
      this.SET_ACTIVE_NODE_CTA_LINK('') // backend needs an empty string, not null
    },
    /**
     * mutes user click if split path toggle is enabled
     * or if existing node being viewed in modal is a child
     * of a split path.
     */
    handleOperatorClick (operator: NodeOperator): void {
      if (this.addSplitPath) return
      if (this.hasSiblings) return
      this.SET_ACTIVE_NODE_OPERATOR(operator)
    },
    setAddSplitPath (value: boolean): void {
      this.addSplitPath = value
    },
    handleStatusCheckClick (): void {
      if (this.disableStep) return
      // set initial value for operator
      this.SET_ACTIVE_NODE_OPERATOR('no' as NodeOperator)
      this.setShowStatusModal(true)
    },
    handleResize (): void {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(this.setDagContainerHeight, 500)
    },
    setDagContainerHeight (): void {
      this.dagContainerHeight = `${this.Dashboard_getContentHeight() - 168 - HEADER_HEIGHT}px`
    },
    setupResizeListener (): void {
      this.resizeListener = window.addEventListener('resize', this.handleResize)
    },
    removeResizeListener (): void {
      window.removeEventListener('resize', this.handleResize)
    },
    maybeSelectFirstOption (): void {
      if (!this.ACTIVE_TRIGGER_TYPE?.category && this.triggerTypeOpts?.length === 1) {
        this.SET_ACTIVE_NODE_TRIGGER_TYPE_ID(this.triggerTypeOpts[0].value)
      }
    },
    navToMetrics (): void {
      this.$router.push({
        name: getMetricsRoute(this.campaign.category),
        params: {
          hotelId: this.$route.params.hotelId,
          workflowId: this.campaign.id
        }
      })
    },
  },
}
