import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import QueryString from 'query-string'
import endpoints from '@/config/endpoints'
import { ParserUtils } from '@/parsers/utils'
import { PromotionParser, EmailMarketingRewardAlgorithmParser, } from '@/parsers'
import { algoRewardOverrideOpts, FALSY } from '@/constants'
import { AlgorithmNameIsTaken } from '@/lib/error-handlers'
import { getTotalRewardsOffered } from '@/utils/reward-algos'

export const ON_PROPERTY_REWARDS_LIST = 'ON_PROPERTY_REWARDS_LIST'
export const MARKETPLACE_REWARDS_LIST = 'MARKETPLACE_REWARDS_LIST'
const { WHITELIST, EXCLUDE } = algoRewardOverrideOpts
const ID = 'id'
const POST = 'post'
const PATCH = 'patch'

const BASE_ALGO = {
  identifier: '',
  maxRewardSelections: undefined,
  onPropertyRewards: undefined,
  marketPlaceRewards: undefined,
  params: [],
  hotelId: undefined,
  whitelistIds: [],
  whitelistIdsParamId: undefined,
  excludeIds: [],
  excludeIdsParamId: undefined
}

export default {
  namespaced: true,
  state: {
    algosFetchSuccess: false,
    rewardsFetchSuccess: false,
    marketplaceRewardsFetchSucess: false,
    onPropertyRewards: {},
    marketPlaceRewards: {},
    algos: {},
    algo: { ...BASE_ALGO },
    pageInfo: {
      page: 1,
      total: undefined,
      pageSize: 10
    },
    // on-property algo rewards
    rewardsPageInfo: {
      page: 1,
      total: undefined,
      pageSize: 10
    },
    // maretplace algo rewards
    marketplaceRewardsPageInfo: {
      page: 1,
      total: undefined,
      pageSize: 10
    }
  },
  getters: {
    TOTAL_REWARDS_OFFERED (state) {
      const { onPropertyRewards, marketPlaceRewards } = state.algo
      return getTotalRewardsOffered({
        onPropertyRewards,
        marketPlaceRewards
      })
    },

    IS_DISABLED (state) {
      return state.algo.identifier.length < 1 ||
        FALSY.includes(state.algo.maxRewardSelections) ||
          FALSY.includes(state.algo.onPropertyRewards) ||
            FALSY.includes(state.algo.marketPlaceRewards)
    },
    GET_REWARD_OVERRIDE_STATUS (getters) {
      return (id) => {
        if (getters.IS_EXCLUDED(id)) return EXCLUDE
        if (getters.IS_WHITELISTED(id)) return WHITELIST
        return null
      }
    },
    IS_EXCLUDED (state) {
      return (id) => {
        return state.algo.excludeIds.indexOf(id) > -1
      }
    },
    IS_WHITELISTED (state) {
      return (id) => {
        return state.algo.whitelistIds.indexOf(id) > -1
      }
    },
    [ON_PROPERTY_REWARDS_LIST] (state) {
      return Object.values(state.onPropertyRewards)
    },
    [MARKETPLACE_REWARDS_LIST] (state) {
      return Object.values(state.marketPlaceRewards)
    },
    ALGOS_LIST (state) {
      return Object.values(state.algos)
    },
    GET_ALGO (state) {
      return ({ id }) => state.algos[id]
    },
    ALGOS_EMPTY (state) {
      return Object.keys(state.algos).length === 0 &&
        state.algosFetchSuccess === false
    },
    ALGO_PAYLOAD (state, _, rootState) {
      return () => {
        const hotel = rootState.hotel.cradleHotelsObj[rootState.route.params.hotelId]
        if (hotel === undefined) {
          console.warn('ALGO_PAYLOAD hotel is undefined')
          return
        }
        const { cradleId } = hotel

        // remove the old references
        const oldParams = state.algo.params.filter((param) => {
          return ['blacklist_ids', 'whitelist_ids'].indexOf(param.key) === -1
        })

        const newParams = []
        if (state.algo.excludeIds.length > 0) {
          newParams.push({
            id: state.algo.excludeIdsParamId,
            key: 'blacklist_ids',
            value_type: 'str',
            value: state.algo.excludeIds.join(',')
          })
        }
        if (state.algo.whitelistIds.length > 0) {
          newParams.push({
            id: state.algo.whitelistIdsParamId,
            key: 'whitelist_ids',
            value_type: 'str',
            value: state.algo.whitelistIds.join(',')
          })
        }
        // need to preserve the unused params. if they are not added
        // to the payload they will be deleted.
        const params = [
          ...oldParams,
          ...newParams
        ]

        const payload = {
          hotel: cradleId,
          identifier: state.algo.identifier,
          on_property: state.algo.onPropertyRewards,
          brand_safe: state.algo.marketPlaceRewards,
          max_reward_selections: state.algo.maxRewardSelections,
          algorithm_params: params,
        }

        if (typeof state.algo.share === 'number') {
          payload.share = (state.algo.share / 100)
        }

        console.log('[ALGO_PAYLOAD]', JSON.stringify(payload, null, 2))
        return payload
      }
    },
    BULK_SHARE_UPDATE_PAYLOAD (state) {
      return () => {
        const payload = Object.values(state.algos)
          .map(algo => {
            return { id: algo.id, share: (algo.share / 100) }
          })
        console.log('[BULK_SHARE_UPDATE_PAYLOAD]', payload)
        return payload
      }
    }
  },
  mutations: {
    SET_ALGOS_FETCH_SUCCESS (state, bool) {
      state.algosFetchSuccess = bool
    },
    SET_REWARDS_FETCH_SUCCESS (state, bool) {
      state.rewardsFetchSuccess = bool
    },
    SET_MARKETPLACE_REWARDS_FETCH_SUCCESS (state, bool) {
      state.marketplaceRewardsFetchSucess = bool
    },
    SET_ALGOS (state, algos) {
      state.algos = algos
    },
    MERGE_ALGOS (state, algo) {
      state.algos = { ...state.algos, ...algo }
    },
    SET_ON_PROPERTY_REWARDS (state, rewards) {
      state.onPropertyRewards = rewards
    },
    SET_MARKETPLACE_REWARDS (state, value) {
      state.marketPlaceRewards = value
    },
    SET_ALGO_IDENTIFIER (state, name) {
      state.algo.identifier = name
    },
    SET_ALGO_ON_PROPERTY_REWARDS (state, value) {
      state.algo.onPropertyRewards = value
    },
    SET_ALGO_MARKETPLACE_REWARDS (state, value) {
      state.algo.marketPlaceRewards = value
    },
    SET_ALGO_SELECTABLE_LIMIT (state, value) {
      state.algo.maxRewardSelections = value
    },
    CLEAR_ALGOS (state) {
      state.algos = {}
    },
    SET_ALGO (state, algo) {
      console.log('[SET_ALGO]', algo)
      state.algo = algo
    },
    RESET_ALGO (state) {
      state.algo = { ...BASE_ALGO }
    },
    RESET_ALGOS (state) {
      state.algos = {}
    },
    RESET_ALGO_REWARDS (state) {
      state.onPropertyRewards = {}
      state.marketPlaceRewards = {}
    },
    // algorithsm pagination
    SET_PAGE (state, page) {
      state.pageInfo.page = page
    },
    SET_PAGE_SIZE (state, pageSize) {
      state.pageInfo.pageSize = pageSize
    },
    SET_PAGE_INFO (state, pageInfo) {
      state.pageInfo = pageInfo
    },
    // rewards pagination
    SET_REWARDS_PAGE (state, page) {
      console.log('[SET_REWARDS_PAGE]', page)
      state.rewardsPageInfo.page = page
    },
    SET_REWARDS_PAGE_SIZE (state, pageSize) {
      console.log('[SET_REWARDS_PAGE_SIZE]', pageSize)
      state.rewardsPageInfo.pageSize = pageSize
    },
    SET_REWARDS_PAGE_TOTAL (state, total) {
      console.log('[SET_REWARDS_PAGE_TOTAL], total:', total)
      state.rewardsPageInfo.total = total
    },
    // marketplace rewards pagination
    SET_MARKETPLACE_REWARDS_PAGE (state, page) {
      console.log('[SET_MARKETPLACE_REWARDS_PAGE]', page)
      state.marketplaceRewardsPageInfo.page = page
    },
    SET_MARKETPLACE_REWARDS_PAGE_SIZE (state, pageSize) {
      console.log('[SET_MARKETPLACE_REWARDS_PAGE_SIZE]', pageSize)
      state.marketplaceRewardsPageInfo.pageSize = pageSize
    },
    SET_MARKETPLACE_REWARDS_PAGE_TOTAL (state, total) {
      console.log('[SET_MARKETPLACE_REWARDS_PAGE_TOTAL], total:', total)
      state.marketplaceRewardsPageInfo.total = total
    },
    // override algo
    ADD_WHITELIST_ID (state, rewardId) {
      console.log('[ADD_WHITELIST_ID]', rewardId)
      state.algo.whitelistIds.push(rewardId)
    },
    REMOVE_WHITELIST_ID (state, rewardId) {
      console.log('[REMOVE_WHITELIST_ID]', rewardId)
      const idx = state.algo.whitelistIds.indexOf(rewardId)
      state.algo.whitelistIds.splice(idx, 1)
    },
    ADD_EXCLUDE_ID (state, rewardId) {
      console.log('[ADD_EXCLUDE_ID]', rewardId)
      state.algo.excludeIds.push(rewardId)
    },
    REMOVE_EXCLUDE_ID (state, rewardId) {
      console.log('[REMOVE_EXCLUDE_ID]', rewardId)
      const idx = state.algo.excludeIds.indexOf(rewardId)
      state.algo.excludeIds.splice(idx, 1)
    },
    UPDATE_SHARE (state, { id, share }) {
      Vue.set(state.algos[id], 'share', share)
    }
  },
  actions: {
    RESET_STATE ({ commit }) {
      commit('RESET_ALGO')
      commit('RESET_ALGO_REWARDS')
    },
    UPDATE_REWARD_OVERRIDE ({ getters, commit }, { rewardId, status }) {
      console.log('[UPDATE_REWARD_OVERRIDE]', rewardId, status)
      if (status === EXCLUDE) {
        if (getters.IS_WHITELISTED(rewardId)) {
          commit('REMOVE_WHITELIST_ID', rewardId)
        }
        commit('ADD_EXCLUDE_ID', rewardId)
      } else if (status === WHITELIST) {
        if (getters.IS_EXCLUDED(rewardId)) {
          commit('REMOVE_EXCLUDE_ID', rewardId)
        }
        commit('ADD_WHITELIST_ID', rewardId)
      } else if (status === null) {
        if (getters.IS_WHITELISTED(rewardId)) {
          commit('REMOVE_WHITELIST_ID', rewardId)
        }
        if (getters.IS_EXCLUDED(rewardId)) {
          commit('REMOVE_EXCLUDE_ID', rewardId)
        }
      } else {
        console.warn('Invalid status', status)
      }
    },
    async CREATE_OR_UPDATE_ALGO (
      { rootState, getters, dispatch },
      {
        id,
        nextRoute,
        endpoint = endpoints.cradle.emailMarketingRewardAlgorithmRoute
      }
    ) {
      let _endpoint
      let method
      if (id) {
        _endpoint = `${endpoint}${id}/`
        method = PATCH
      } else {
        _endpoint = endpoint
        method = POST
      }

      const payload = getters.ALGO_PAYLOAD()

      let response
      let error

      try {
        response = await axios[method](
          _endpoint,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
              'Content-Type': 'application/json'
            }
          }
        )
      } catch (err) {
        error = err.response.data
        if (AlgorithmNameIsTaken.check(error.non_field_errors && error.non_field_errors[0]).isMatch) {
          error = new Error(AlgorithmNameIsTaken.friendlyMessage)
        }
      }
      console.log('[CREATE_OR_UPDATE_ALGO]', error || response)

      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        if (nextRoute) {
          router.push({
            name: nextRoute.name,
            params: nextRoute.params || rootState.route.params,
            query: nextRoute.query
          })
        }
      }
    },
    /**
     *
     * @param rewardType string - supports on-property or marketplace
     */
    async FETCH_ALGO_REWARDS (
      { rootState, commit, dispatch, state },
      { excludeCampaignRewards = false },
    ) {
      const hotelId = rootState.route.params.hotelId
      const { hotelCode: hotelApiName } = rootState.hotel.cradleHotelsObj[hotelId]
      const qs = {
        page: state.rewardsPageInfo.page,
        page_size: state.rewardsPageInfo.pageSize,
        on_property_hotels: hotelApiName,
        division: 'op'
      }
      if (excludeCampaignRewards) {
        qs.is_for_campaign = false
      }
      const queryString = QueryString.stringify(qs)
      const endpoint = `${endpoints.mesa.oprmPromotionRoute}?${queryString}`
      console.log('[FETCH_ALGO_REWARDS] endpoint', endpoint)

      let response
      let error

      try {
        commit('SET_REWARDS_FETCH_SUCCESS', false)
        response = await axios.get(endpoint, {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
          }
        })
      } catch (err) {
        error = err.response.data
      }
      console.log('FETCH_ALGO_REWARDS', error || response)
      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        const parsedRewards = ParserUtils.getParsedObject({
          rawList: response.data.results,
          parser: PromotionParser.parsePromotion,
          indexKey: 'id',
        })
        commit('SET_ON_PROPERTY_REWARDS', parsedRewards)
        commit('SET_REWARDS_PAGE_TOTAL', response.data.count)
        commit('SET_REWARDS_FETCH_SUCCESS', true)
      }
    },
    async FETCH_ALGOS (
      { commit, dispatch, rootState },
      {
        hotelLegacyId, // TODO remove
        page = 1,
        pageSize = 10,
        fetchAll = false,
        override = true,
        search,
        nextUrl,
        endpoint = endpoints.cradle.emailMarketingRewardAlgorithmRoute,
        parser = EmailMarketingRewardAlgorithmParser.parseAlgorithm
      }
    ) {
      console.log('[FETCH_ALGOS] endpoint arg:', endpoint)
      commit('SET_ALGOS_FETCH_SUCCESS', false)
      console.log(`[FETCH_ALGOS] page: ${page} pageSize: ${pageSize} hotelLegacyId: ${hotelLegacyId}`)
      const queryParams = {
        page,
        page_size: pageSize,
        hotel__legacy_id: rootState.route.params.hotelId
      }
      if (search) queryParams.search = search
      const queryString = QueryString.stringify(queryParams)
      let _endpoint = `${endpoint}?${queryString}`
      if (nextUrl) {
        _endpoint = nextUrl.replace('http', 'https')
      }
      console.log('[FETCH_ALGOS] _endpoint:', _endpoint)

      let response
      let error

      try {
        response = await axios.get(
          _endpoint,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
            }
          })
      } catch (err) {
        error = err.response.data
      }
      console.log('[FETCH_ALGOS]', error || response)

      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        const parsedAlgos = ParserUtils.getParsedObject({
          rawList: response.data.results,
          parser: parser,
          indexKey: ID,
        })
        if (override) {
          commit('SET_ALGOS', parsedAlgos)
        } else {
          commit('MERGE_ALGOS', parsedAlgos)
        }
        commit('SET_PAGE_INFO', {
          total: response.data.count,
          page: page,
          pageSize: pageSize
        })
        commit('SET_ALGOS_FETCH_SUCCESS', true)
        if (fetchAll && response.data.next) {
          await dispatch('FETCH_ALGOS', { nextUrl: response.data.next, fetchAll: true, override })
        }
      }
    },
    async FETCH_MARKETPLACE_ALGO_REWARDS (
      { rootState, commit, dispatch, state },
      { excludeCampaignRewards = false },
    ) {
      const hotelId = rootState.route.params.hotelId
      const { campaignCost } = rootState.hotel.cradleHotelsObj[hotelId]
      const qs = {
        page: state.marketplaceRewardsPageInfo.page,
        page_size: state.marketplaceRewardsPageInfo.pageSize,
        max_cost: campaignCost
      }
      if (excludeCampaignRewards) {
        qs.is_for_campaign = false
      }
      const queryString = QueryString.stringify(qs)
      const endpoint = `${endpoints.mesa.marketplacePromotionRoute}?${queryString}`

      console.log('[FETCH_MARKETPLACE_ALGO_REWARDS] endpoint', endpoint)

      let response
      let error

      try {
        commit('SET_MARKETPLACE_REWARDS_FETCH_SUCCESS', false)
        response = await axios.get(endpoint, {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
          }
        })
      } catch (err) {
        error = err.response.data
      }
      console.log('FETCH_MARKETPLACE_ALGO_REWARDS', error || response)

      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        const parsedRewards = ParserUtils.getParsedObject({
          rawList: response.data.results,
          parser: PromotionParser.parsePromotion,
          indexKey: 'id',
        })
        commit('SET_MARKETPLACE_REWARDS', parsedRewards)
        commit('SET_MARKETPLACE_REWARDS_PAGE_TOTAL', response.data.count)
        commit('SET_MARKETPLACE_REWARDS_FETCH_SUCCESS', true)
      }
    },
    UPDATE_SHARES ({ commit }, shareDict) {
      Object.entries(shareDict).forEach(([id, share]) => {
        commit('UPDATE_SHARE', { id, share })
      })
    },
    async UPDATE_BULK_SHARES ({ rootState, getters, dispatch }) {
      let error

      const payload = { hotel_algorithms: getters.BULK_SHARE_UPDATE_PAYLOAD() }

      try {
        await axios.post(
          endpoints.cradle.hotelRewardAlgorithmBulkShareUpdate,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
              'Content-Type': 'application/json'
            }
          }
        )
      } catch (e) {
        error = e
      }

      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        dispatch(
          'messages/ADD_SUCCESS',
          { message: 'Booking algorithms successfully updated.' },
          { root: true }
        )
      }
    }
  }
}
