
























































































































import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { userViews, FALSY } from '@/constants'
import Table from '@/components/table/Table.vue'
import Header from '@/components/common/Header.vue'
import Textfield from '@/components/textfield/Textfield.vue'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton.vue'
import endpoints from '@/config/endpoints'
import { HotelRewardAlgorithmParser } from '@/parsers'

export type ShareDict = Record<string, { share: number }>

export type ShareTotalStyles = {
  display: string;
  flexDirection: string;
  alignItems: string;
  justifyContent: string;
  width: string;
  background: string;
  border: string;
}

export default {
  name: 'HotelRewardAlgorithmsTable',
  components: {
    Table,
    Header,
    StepperButton,
    Textfield
  },
  data () {
    return {
      show: false,
      shareDict: {} as ShareDict,
      infoIcon: ['fas', 'info-circle'],
      copy: {
        title: 'Booking Algorithms',
        shareTotal: 'Share Total',
        messages: {
          success: 'Algorithm shares were saved successfully!',
          warning: 'Warning: Unable to save. Total share does not equal 100%.'
        },
        buttons: {
          create: '+ Create Algorithm',
          save: 'Save',
        },
        errors: {
          shareTotalNot100: 'Total does not equal 100%'
        }
      },
      columns: [
        {
          key: 'identifier',
          name: 'Name',
          sortable: false,
          alignment: 'flex-start',
          width: '100',
        },
        {
          key: 'onPropertyRewards',
          name: 'On-Property',
          sortable: false,
          alignment: 'flex-start',
          width: '20',
        },
        {
          key: 'marketPlaceRewards',
          name: 'Marketplace',
          sortable: false,
          alignment: 'flex-start',
          width: '20',
        },
        {
          key: 'maxRewardSelections',
          name: 'Rewards Selectable',
          sortable: false,
          alignment: 'flex-start',
          width: '35',
        },
        {
          key: 'share',
          name: 'Share',
          sortable: false,
          alignment: 'flex-start',
          width: '30',
        },
      ],
    }
  },
  computed: {
    ...mapState('ram', [
      'algosFetchSuccess',
      'pageInfo',
      'hotelRewardAlgorithmsFetchSuccess'
    ]),
    ...mapGetters('ram', [
      'ALGOS_LIST',
    ]),
    computedShareTotal (): number {
      let total = 0
      Object.values(this.shareDict).forEach((share: number) => {
        total += share
      })
      return total
    },
    shareTotalNot100 (): boolean {
      return this.computedShareTotal !== 100
    },
    shareTotalStyles (): ShareTotalStyles {
      return {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '200px',
        background: this.$theme.colors.gray['100'],
        border: this.shareTotalNot100
          ? `1px solid ${this.$theme.colors.orange['500']}`
          : `1px solid ${this.$theme.colors.green['500']}`
      }
    }
  },
  async mounted (): Promise<void> {
    this.RESET_ALGOS()
    await this.fetchHotelRewardAlgorithmsPaginated()
    this.setup()
    this.show = true
  },
  methods: {
    ...mapActions('ram', [
      'FETCH_ALGOS',
      'UPDATE_SHARES',
      'UPDATE_BULK_SHARES'
    ]),
    ...mapMutations('ram', [
      'SET_PAGE',
      'SET_PAGE_SIZE',
      'RESET_ALGOS'
    ]),
    ...mapActions('messages', [
      'ADD_ERROR',
      'ADD_SUCCESS',
      'CLEAR_ERRORS'
    ]),
    // TODO refactor into util. taken from Users.vue.
    // TODO add spec
    async update (): Promise<void> {
      if (this.shareTotalNot100) {
        this.ADD_ERROR({ message: this.copy.messages.warning })
        return
      }
      // update all shares in the store because action/getter will rely on store
      this.UPDATE_SHARES(this.shareDict)
      await this.UPDATE_BULK_SHARES()
      this.setup()
    },
    navToHotelRewardAlgoEdit (id: number): void {
      this.$router.push({
        name: userViews.HOTEL_REWARD_ALGOS_WIZARD_EDIT,
        params: {
          hotelId: this.$route.params.hotelId,
          algoId: id,
        }
      })
    },
    navToHotelRewardAlgoNew (): void {
      this.$router.push({
        name: userViews.HOTEL_REWARD_ALGOS_WIZARD_NEW,
        params: {
          hotelId: this.$route.params.hotelId,
        }
      })
    },
    async fetchHotelRewardAlgorithmsPaginated (): Promise<void> {
      const { page, pageSize } = this.pageInfo
      await this.FETCH_ALGOS({
        page,
        pageSize,
        endpoint: endpoints.cradle.hotelRewardAlgorithmRoute,
        parser: HotelRewardAlgorithmParser.parseAlgorithm
      })
    },
    updatePage (pageNumber: number): void {
      this.SET_PAGE(pageNumber)
      this.fetchHotelRewardAlgorithmsPaginated()
    },
    updatePageSize (pageSize: number): void {
      this.SET_PAGE_SIZE(pageSize)
      this.fetchHotelRewardAlgorithmsPaginated()
    },
    setup (): void {
      // create dictionary of algo shares key'd on ID
      const dict = this.ALGOS_LIST.reduce((acc, algo) => {
        if (!acc[algo.id]) {
          acc[algo.id] = algo.share
        }
        return acc
      }, {})
      this.shareDict = dict
    },
    setShareValue (value: string | void, algoId: number): void {
      if (FALSY.includes(this.shareDict[algoId])) return

      let _value = 0

      // expects value to be a stringified number from <input>
      if (typeof value === 'string' && ![undefined, null, ''].includes(value)) {
        _value = Number(parseFloat(value).toFixed(0))
      }

      this.shareDict[algoId] = _value
    },
    getShareValue (algoId: number): number {
      return this.shareDict[algoId] || 0
    },
    // will prevent use from adding decimal place and alpha chars
    onlyNumbers (event: KeyboardEvent): boolean | void {
      return (event.charCode === 8 || event.charCode === 0 || event.charCode === 13)
        ? null
        : event.charCode >= 48 && event.charCode <= 57
    }
  }
}
