

























































































































































































































































































































































































































































































































































































































































import { mapGetters, mapActions, mapState } from 'vuex'
import { userTypes, userViews, merchantViews, menuStyles } from '@/constants'
import moment from 'moment'
import Header from '@/components/common/Header.vue'
import Modal from '@/components/modal/Modal.vue'
import Toolbar from '@/components/admin/Toolbar.vue'
import Textfield from '@/components/textfield/Textfield.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import Label from '@/components/label/Label.vue'
import Checkbox from '@/components/checkbox/Checkbox.vue'
import Table from '@/components/table/Table.vue'
import Status2 from '@/components/status2/Status2.vue'
import Button from '@/components/button/Button.vue'
import ActionEllipses from '@/components/icons/ActionEllipses.vue'
import Check from '@/components/icons/Check.vue'
import Close from '@/components/icons/Close.vue'
import Select from '@/components/select/Select.vue'

const actions = {
  EDIT: 'Edit',
  DISABLE: 'Disable',
  ENABLE: 'Enable',
  DELETE: 'Delete',
  RESET_PW: 'Reset PW',
}

export default {
  name: 'Users',
  components: {
    Header,
    Modal,
    Toolbar,
    Textfield,
    Dropdown,
    Label,
    Checkbox,
    Table,
    Status2,
    Button,
    ActionEllipses,
    Check,
    Close,
    Select,
  },
  provide () {
    return {
      // TODO - type with UserManagement.ProvidedFunctions
      Users__toggleShowAddUser: this.toggleShowAddUser,
      Users__createNewMerchant: this.createNewMerchant,
    }
  },
  data () {
    return {
      menuStyles,
      pgSize: 10,
      columns: [
        {
          key: 'enabled',
          name: 'Enabled',
          sortable: true,
          alignment: 'center',
          width: '100',
        },
        {
          key: 'email',
          name: 'Email',
          sortable: true,
          alignment: 'flex-start',
          width: '300',
        },
        {
          key: 'type',
          name: 'Type',
          sortable: true,
          alignment: 'flex-start',
          width: '120',
        },
        {
          key: 'reportingAccess',
          name: 'Reporting',
          sortable: true,
          alignment: 'center',
          width: '112',
        },
        {
          key: 'oprmAccess',
          name: 'OPRM',
          sortable: true,
          alignment: 'center',
          width: '87',
        },
        {
          key: 'hssEditorAccess',
          name: 'Editor',
          sortable: true,
          alignment: 'center',
          width: '85',
        },
        {
          key: 'edmAccess',
          name: 'Workflows',
          sortable: true,
          alignment: 'center',
          width: '85',
        },
        {
          key: 'rewardAlgoAccess',
          name: 'Algos',
          sortable: true,
          alignment: 'center',
          width: '85',
        },
        {
          key: 'flexAccess',
          name: 'Flex',
          sortable: true,
          alignment: 'center',
          width: '85',
        },
        {
          key: 'actions',
          name: 'Actions',
          sortable: false,
          alignment: 'center',
          width: '85',
        },
      ],
      confirmCreateUser: false,
      confirmEditUser: false,
      confirmDeleteUser: false,
      confirmDisableUser: false,
      confirmResetPw: false,
      confirmEnableUser: false,
      selectedUser: undefined,
      showAddUser: false,
      showAdminUsers: true,
      showGroupUsers: true,
      showHotelUsers: true,
      showMerchantUsers: true,

      hotelFilterSelection: undefined,
      groupFilterSelection: undefined,
      searchStringFilterValue: undefined,

      // edit user data props
      showEditUser: false,
      editUserFormValues: {
        user: {
          email: undefined,
          type: undefined
        },
        propertyIds: [],
        hssEditorAccess: false,
        reportingAccess: false,
        oprmAccess: false,
        edmAccess: false,
        rewardAlgoAccess: false,
        flexAccess: false,
      },
      userFormValues: {
        email: undefined,
        type: undefined,
        hotelPropertyIds: [],
        groupPropertyIds: [],
        merchantIds: [],
        hssEditorAccess: false,
        reportingAccess: false,
        oprmAccess: false,
        edmAccess: false,
        rewardAlgoAccess: false,
        flexAccess: false,
      },
      destructive: true,
      modalStyle: {
        width: '700px'
      },
      copy: {
        all: 'All',
        header: 'User Administration',
        navigation: {
          DASHBOARD_VIEW: 'dashboard view',
          LOGOUT: 'logout'
        },
        filters: {
          SECTION_HEADER: 'Filter by user type',
          FILTER_BY_HOTEL: 'filter by hotel',
          FILTER_BY_GROUP: 'filter by group',
          SEARCHBAR_PLACEHOLDER: 'search',
          SEARCH_USER_PLACEHOLDER: 'Start typing to search...',
          SEARCH_HOTEL_PLACEHOLDER: 'Filter by hotel',
          SEARCH_GROUP_PLACEHOLDER: 'Filter by group',
        },
        buttons: {
          ADD_NEW_USER: 'add new user',
          HIDE_ADMINS: 'hide admins',
          SHOW_ADMINS: 'show admins',
          SUBMIT: 'submit'
        },
        modalActions: {
          cancel: 'Cancel',
          submit: 'Yes',
        },
        editModal: {
          buttons: {
            cancel: 'Cancel',
            submit: 'Save',
          },
        },
        passwordStatus: {
          FORCE_CHANGE_PASSWORD: 'temp',
          CONFIRMED: 'created',
        },
        enabledStatus: {
          true: 'enabled',
          false: 'disabled',
        },
        userInfo: {
          type: 'Access Type',
          passwordStatus: 'Password Status',
          emailVerified: 'Email Verified',
          createdFrom: 'Created From',
          createdOn: 'Created On',
          lastModified: 'Last Modified',
        },
        accessLabels: {
          moduleAccess: 'Module Access',
          propertyReporting: 'Property Reporting',
          rewardManager: 'Reward Manager',
          embeddedDesigns: 'Embedded Design Editor',
          workflows: 'Workflows',
          rewardAlgoAccess: 'Booking Algorithms',
          flexAccess: 'Laasie Flex'
        }
      },
      closeIconWidth: 16,
      checkIconWidth: 20,
      actionsIconWidth: 3,
      userActions: {
        options: [
          {
            label: actions.EDIT,
            id: 1
          },
          {
            label: actions.DELETE,
            id: 2
          },
          {
            label: actions.DISABLE,
            id: 3
          },
          {
            label: actions.ENABLE,
            id: 4
          },
          {
            label: actions.RESET_PW,
            id: 5
          }
        ],
        label: '',
        button: {
          type: 'default',
          width: 'auto',
          size: 'round',
        }
      }
    }
  },
  computed: {
    ...mapState([
      'showSessionRefreshModal',
    ]),
    ...mapGetters('hotel', [
      'hotelsList',
      'hotelGroupList',
      'hotelObjsByCradleId'
    ]),
    ...mapGetters('userManagement', [
      'usersList',
      'getHotelById',
      'getHotelsById',
      'getHotelById',
      'getGroupById',
      'getHotelName',
      'getHotelNames',
      'getUserByEmail',
      'hotelAccessDropdown'
    ]),
    ...mapGetters('merchant', [
      'merchantAccessDropdown',
      'getMerchantNames',
      'getMerchantsById',
    ]),
    checkColor () {
      return this.$theme.colors.green['500']
    },
    closeColor () {
      return this.$theme.colors.gray['300']
    },
    actionsColor () {
      return this.$theme.colors.gray['800']
    },
    userTypes () {
      return Object.values(userTypes).map(type => ({
        label: type.slice(0, 1).toUpperCase() + type.slice(1, type.length),
        value: type,
      }))
    },
    showHotelUserForm () {
      return this.userFormValues.type === userTypes.HOTEL
    },
    showGroupUserForm () {
      return this.userFormValues.type === userTypes.GROUP
    },
    showAdminUserForm () {
      return this.userFormValues.type === userTypes.ADMIN
    },
    showMerchantUserForm () {
      return this.userFormValues.type === userTypes.MERCHANT
    },
    filteredUsers () {
      let users = this.usersList
      if (this.hotelFilterSelection) {
        users = this.usersList.filter(user => {
          return user.hotelIds.includes(this.hotelFilterSelection)
        })
      }

      if (this.groupFilterSelection) {
        users = users.filter(user => {
          return user.groupIds.includes(this.groupFilterSelection)
        })
      }

      if (this.searchStringFilterValue) {
        users = users.filter(user => {
          return user.searchString.includes(this.searchStringFilterValue)
        })
      }

      if (!this.showGroupUsers) {
        users = users.filter(user => {
          return user.type !== userTypes.GROUP
        })
      }

      if (!this.showHotelUsers) {
        users = users.filter(user => {
          return user.type !== userTypes.HOTEL
        })
      }

      if (!this.showMerchantUsers) {
        users = users.filter(user => {
          return user.type !== userTypes.MERCHANT
        })
      }

      if (!this.showAdminUsers) {
        users = users.filter(user => {
          return user.type !== userTypes.ADMIN
        })
      }

      return users
    },
    isAdmin () {
      return (user) => user.type === userTypes.ADMIN
    },
    isGroup () {
      return (user) => user.type === userTypes.GROUP
    },
    isHotel () {
      return (user) => user.type === userTypes.HOTEL
    },
    isMerchant () {
      return (user) => user.type === userTypes.MERCHANT
    },
    emptyUser: () => ({
      hotelIds: [
      ],
      groupIds: [
      ],
      username: '',
      createDate: '',
      lastModifiedDate: '',
      enabled: true,
      status: 'FORCE_CHANGE_PASSWORD',
      sub: 'e023a6d8-09aa-4a79-b64f-5f0473f1072e',
      emailVerified: 'true',
      email: 'testuser1@aol.com',
      createEnv: 'prod',
      type: 'hotel'
    }),
    editUserProfile () {
      return this.getUserByEmail(this.editUserFormValues.user.email) || this.emptyUser
    },
    dropdownOptionsStyles () {
      return {
        height: '1.5em'
      }
    }
  },
  async created () {
    this.SET_IS_LOADING(true)
    await Promise.all([
      this.$store.dispatch('userManagement/FETCH_HOTELS_AND_GROUPS'),
      // TODO admin user shouldn't need to re-fetch merchants if coming from MDB
      this.$store.dispatch('merchant/FETCH_MERCHANTS'),
    ])
    await this.$store.dispatch('userManagement/FETCH_USERS')
    this.SET_IS_LOADING(false)
  },
  methods: {
    ...mapActions([
      'SET_IS_LOADING',
    ]),
    handleHotelSelect () {
      this.groupFilterSelection = ''
    },
    handleGroupSelect () {
      this.hotelFilterSelection = ''
    },
    modalTitle (user) {
      return `Edit <span style="text-transform: capitalize">${user.type}</span> User`
    },
    prettyCreatedAt () {
      return moment(this.editUserProfile.createDate).format('ddd DD MMM YYYY, LT')
    },
    prettyUpdatedAt () {
      return moment(this.editUserProfile.lastModifiedDate).format('ddd DD MMM YYYY, LT')
    },
    setShowAddUser (bool) {
      this.showAddUser = bool
    },
    handleEditUser (user) {
      return this.selectUserToEdit(user)
    },
    handleDeleteUser (user) {
      this.confirmDeleteUser = true
      return this.selectUser(user.email)
    },
    handleDisableUser (user) {
      this.confirmDisableUser = true
      return this.selectUser(user.email)
    },
    handleEnableUser (user) {
      this.confirmEnableUser = true
      return this.selectUser(user.email)
    },
    handleResetUserPw (user) {
      this.confirmResetPw = true
      return this.selectUser(user.email)
    },
    getUserMethod (label, user) {
      console.log('actions: ', this.copy.actions)
      const fns = {
        [actions.EDIT]: this.handleEditUser,
        [actions.DELETE]: this.handleDeleteUser,
        [actions.DISABLE]: this.handleDisableUser,
        [actions.ENABLE]: this.handleEnableUser,
        [actions.RESET_PW]: this.handleResetUserPw,
      }
      const fn = fns[label]
      if (fn) {
        fn(user)
      } else {
        console.warn('no corresponding user method found')
      }
    },
    updatePageSize (e) {
      this.pgSize = e.id
    },
    getStatus (status) {
      if (status === true) {
        return 'active'
      } else {
        return 'inactive'
      }
    },
    logout () {
      this.$store.dispatch('LOGOUT_USER')
    },
    navigateToDashboard () {
      this.$router.push({
        name: 'dashboard',
        params: { view: userViews.HOMEPAGE }
      })
    },
    clearUserFormValues () {
      this.userFormValues.email = undefined
      this.userFormValues.type = undefined
      this.userFormValues.hotelPropertyIds = []
      this.userFormValues.groupPropertyIds = []
      this.userFormValues.merchantIds = []
      this.userFormValues.hssEditorAccess = false
      this.userFormValues.reportingAccess = false
      this.userFormValues.oprmAccess = false
      this.userFormValues.edmAccess = false
      this.userFormValues.rewardAlgoAccess = false
      this.userFormValues.flexAccess = false
      this.showAddUser = false
    },
    clearEditFormValues () {
      this.editUserFormValues.user = {
        email: undefined,
        type: undefined
      }
      this.editUserFormValues.propertyIds = []
      this.editUserFormValues.hssEditorAccess = false
      this.editUserFormValues.reportingAccess = false
      this.editUserFormValues.oprmAccess = false
      this.editUserFormValues.edmAccess = false
      this.editUserFormValues.rewardAlgoAccess = false
      this.editUserFormValues.flexAccess = false
      this.toggleShowEditUser()
    },
    async submitEditForm () {
      if (
        !this.isGroup(this.editUserFormValues.user) &&
        this.editUserFormValues.propertyIds.length < 1
      ) {
        this.$store.dispatch('messages/ADD_ERROR',
          new Error('Missing property assignment')
        )
        return
      }
      if (this.isMerchant(this.editUserFormValues.user)) {
        await this.$store.dispatch(
          'merchant/UPDATE_COGNITO_USER_MERCHANT_IDS',
          {
            userProfile: this.editUserFormValues.user,
            merchantIds: this.editUserFormValues.propertyIds
          }
        )
        this.$store.dispatch('userManagement/FETCH_USERS')
      }

      if (!this.isAdmin(this.editUserFormValues.user)) {
        this.$store.dispatch('userManagement/EDIT_USER', {
          createEnv: this.editUserFormValues.user.createEnv,
          userType: this.editUserFormValues.user.type,
          userEmail: this.editUserFormValues.user.email,
          propertyIds: this.editUserFormValues.propertyIds,
          hssEditorAccess: this.editUserFormValues.hssEditorAccess, // will be coerced to string by action
          reportingAccess: this.editUserFormValues.reportingAccess,
          oprmAccess: this.editUserFormValues.oprmAccess,
          edmAccess: this.editUserFormValues.edmAccess,
          rewardAlgoAccess: this.editUserFormValues.rewardAlgoAccess,
          flexAccess: this.editUserFormValues.flexAccess
        })
      }

      this.clearEditFormValues()
    },
    toggleShowEditUser () {
      this.showEditUser = !this.showEditUser
    },
    selectUserToEdit (user) {
      this.clearEditFormValues()
      this.editUserFormValues.user = { ...user }
      if (this.isHotel(user) || this.isGroup(user) || this.isAdmin(user)) {
        this.editUserFormValues.hssEditorAccess = user.hssEditorAccess
        this.editUserFormValues.reportingAccess = user.reportingAccess
        this.editUserFormValues.oprmAccess = user.oprmAccess
        this.editUserFormValues.edmAccess = user.edmAccess
        this.editUserFormValues.rewardAlgoAccess = user.rewardAlgoAccess
        this.editUserFormValues.flexAccess = user.flexAccess
      }
      if (this.isGroup(user)) {
        this.editUserFormValues.propertyIds = user.groupIds
      }
      if (this.isHotel(user)) {
        // sets up dropdown to include user's existing hotels
        this.editUserFormValues.propertyIds = user.hotelIds
      }
      if (this.isMerchant(user)) {
        this.editUserFormValues.propertyIds = user.merchantIds
      }
      this.showEditUser = true
    },
    selectUser (user) {
      this.selectedUser = user
    },
    submitUserForm () {
      this.confirmCreateUser = false
      // user email will be forced to lower case in CREATE_NEW_USER action
      // validations
      if (!this.userFormValues.email) {
        this.$store.dispatch('messages/ADD_ERROR',
          new Error('Missing email')
        )
        return
      }
      if (!this.userFormValues.type) {
        this.$store.dispatch('messages/ADD_ERROR',
          new Error('Missing user type')
        )
        return
      }
      if (
        (this.userFormValues.type === userTypes.HOTEL &&
        this.userFormValues.hotelPropertyIds.length < 1) ||
          (this.userFormValues.type === userTypes.GROUP &&
              this.userFormValues.groupPropertyIds.length === 0) ||
                (this.userFormValues.type === userTypes.MERCHANT &&
                  this.userFormValues.merchantIds.length < 1)
      ) {
        this.$store.dispatch('messages/ADD_ERROR',
          new Error('Missing property assignment')
        )
        return
      }
      this.$store.dispatch(
        'userManagement/CREATE_NEW_USER',
        { newUser: this.userFormValues }
      )
      this.clearUserFormValues()
    },
    clearError (id) {
      this.$store.dispatch('userManagement/CLEAR_ERROR', id)
    },
    toggleShowAddUser () {
      // because the button is always visible, when a user wants
      // to see form and they're down in the list, we need to scroll to top
      if (!this.showAddUser) {
        window.scroll(0, 0)
      }
      this.showAddUser = !this.showAddUser
    },
    getGroupName (groupId) {
      return this.getGroupById(groupId).name
    },
    getNames (objs = []) {
      return objs.map(obj => obj.name)
    },
    passwordStatus (status) {
      return this.copy.passwordStatus[status]
    },
    toggleShowAdminUsers () {
      this.showAdminUsers = !this.showAdminUsers
    },
    disableUser () {
      this.$store.dispatch(
        'userManagement/DISABLE_USER',
        { userEmail: this.selectedUser }
      )
      this.selectedUser = undefined
      this.confirmDisableUser = false
    },
    enableUser () {
      this.$store.dispatch(
        'userManagement/ENABLE_USER',
        { userEmail: this.selectedUser }
      )
      this.selectedUser = undefined
      this.confirmEnableUser = false
    },
    deleteUser () {
      this.$store.dispatch(
        'userManagement/DELETE_USER',
        { userEmail: this.selectedUser }
      )
      this.selectedUser = undefined
      this.confirmDeleteUser = false
    },
    resetUserPassword () {
      this.$store.dispatch(
        'userManagement/RESET_USER_PASSWORD',
        { userEmail: this.selectedUser }
      )
      this.selectedUser = undefined
      this.confirmResetPw = false
    },
    async createNewMerchant () {
      console.log('createNewMerchant')
      this.$store.commit('merchant/SET_MERCHANT_ID', 0)
      this.$router.push({
        name: merchantViews.MERCHANT_ACCOUNT_NEW
      })
    },
    /**
     * hides add user modal and shows confirmation modal
     * TODO maybe one method that toggles
     */
    handleAddNewUserSubmit () {
      this.showAddUser = false
      this.confirmCreateUser = true
    },
    handleAddNewUserCancel () {
      this.showAddUser = true
      this.confirmCreateUser = false
    },
    setUserType (event) {
      this.userFormValues.type = event.value
    }
  },

}
