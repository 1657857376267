

























































































































































































































import Autocomplete from '@/components/select/Autocomplete.vue'
import WizardHeader from '@/components/common/WizardHeader.vue'
import Button from '@/components/button/Button.vue'
import TextField from '@/components/textfield/Textfield.vue'
import EmailBuilder from '@/components/hotel-dashboard/email-design-manager/wizard/EmailBuilder.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { EmailDesign } from '@/types'
import { emailDesignTypes, RELEASE_TYPE_OPTIONS } from '@/constants'
import RadioButton from '@/components/radio-button/RadioButton.vue'

const { AUTO_RELEASE, AUTO_SELECT, FLEX_RELEASE } = RELEASE_TYPE_OPTIONS

const { SINGLE_USE } = emailDesignTypes

export default {
  name: 'LaasieFlexWizard',
  components: {
    WizardHeader,
    Button,
    TextField,
    Autocomplete,
    EmailBuilder,
    RadioButton,
  },
  data () {
    return {
      SINGLE_USE,
      AUTO_RELEASE,
      AUTO_SELECT,
      FLEX_RELEASE,
      copy: {
        buttons: {
          sendEmail: 'Send Email',
          sendTest: 'Send Test',
        },
        errors: {
          invalidFirstName: 'Please enter first name.',
          invalidLastName: 'Please enter last name.',
          invalidGuestEmail: 'Please enter guest email.',
          invalidEmailDesign: 'Please select an email design.',
          invalidAlgo: 'Please select a reward algorithm.',
          invalidReleaseType: 'Please choose whether this offer should be Selectable or Pre-selected.',
          invalidTestEmailRecipients: 'Please enter a test email address.',
          missingSender: 'There is no verified send address associated with this account. Please contact your CSM for assistance.',
        },
        headers: {
          editor: 'Send On-Demand Rewards',
          settings: 'Edit Details',
        },
        placeholders: {
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'guest@email.com',
          emailDesign: 'Select your email design',
          emailPreview: 'Select a design under the "Email Design" dropdown.',
          algo: 'Select your reward algorithm',
          testEmail: 'Add email address',
        },
        sectionCaptions: {
          emailDesign: 'To edit an email, please visit the Email Builder page. If an email design is missing from this list, please check that "Email Design Type" on your email\'s settings is set to "Single Send".',
          algo: 'Select which algorithm that will be used to determine the rewards offered to your guest.',
          testEmail1: 'Enter up to 10 email addresses, seperated by commas.',
          testEmail2: 'Need help? Reach out to your CSM at support@laasie.ai',
          selectableBody: '<strong>Selectable</strong> offers are offers where the customer selects rewards from a reward set determined by the reward algorithm. Once the customer selects a reward, it will be instantly redeemable.',
          preSelectableBody: '<strong>Pre-selected</strong> offers are offers where we automatically select rewards for the customer. The rewards are instantly redeemable.'
        },
        sectionHeaders: {
          guestName: 'Guest Name',
          guestEmail: 'Guest Email',
          emailDesign: 'Email Design',
          fromSender: 'From Sender',
          subject: 'Subject',
          algo: 'Reward Algorithm',
          testEmail: 'Test Email',
          selectOrPreSelect: 'Is this flex offer <strong>selectable</strong> or <strong>pre-selected</strong>?',
        },
      },
    }
  },
  computed: {
    ...mapGetters('edm', [
      'SENDER_EMAIL',
      'GET_EMAIL_DESIGN',
      'GET_REQUIRES_REDEMPTION_REWARD_ALGO',
    ]),
    ...mapGetters('flex', [
      'RELEASE_TYPE',
    ]),
    emailDesign (): EmailDesign | Record<string, string> {
      const design = this.GET_EMAIL_DESIGN({ id: this.emailDesignId })
      return design || {}
    },
    firstName: {
      get (): string {
        return this.$store.getters['flex/CUSTOMER_FIRST_NAME']
      },
      set (firstName: string): void {
        this.SET_CUSTOMER_FIRST_NAME(firstName)
      }
    },
    lastName: {
      get (): string {
        return this.$store.getters['flex/CUSTOMER_LAST_NAME']
      },
      set (lastName: string): void {
        this.SET_CUSTOMER_LAST_NAME(lastName)
      }
    },
    emails: {
      get (): string {
        return this.$store.getters['flex/CUSTOMER_EMAILS']
      },
      set (emails: string): void {
        this.SET_CUSTOMER_EMAILS(emails)
      }
    },
    emailDesignId: {
      get (): number {
        return this.$store.getters['flex/EMAIL_DESIGN_ID']
      },
      set (emailDesignId: number): void {
        this.SET_EMAIL_DESIGN_ID(emailDesignId)
      }
    },
    releaseType: {
      get (): number | undefined {
        return this.RELEASE_TYPE
      },
      set (value: number | string): void {
        this.SET_RELEASE_TYPE(value)
      }
    },
    algoId: {
      get (): number {
        return this.$store.getters['flex/ALGO_ID']
      },
      set (algoId: number): void {
        this.SET_ALGO_ID(algoId)
      }
    },
    testEmailRecipients: {
      get (): string {
        return this.$store.getters['flex/TEST_EMAIL_RECIPIENTS']
      },
      set (testEmailRecipients: string): void {
        this.SET_TEST_EMAIL_RECIPIENTS(testEmailRecipients)
      }
    },
    hideGuestName (): boolean {
      return this.emails.includes(',')
    }
  },
  async mounted () {
    this.clearForm()
    if (!this.SENDER_EMAIL) {
      this.ADD_ERROR(new Error(this.copy.errors.missingSender))
    }
  },
  methods: {
    ...mapActions('messages', [
      'ADD_ERROR',
    ]),
    ...mapActions('edm', [
      'FETCH_EMAIL_DESIGNS',
    ]),
    ...mapActions('flex', [
      'SEND_FLEX_EMAIL',
      'SEND_FLEX_EMAILS',
      'SEND_TEST_FLEX_EMAIL',
    ]),
    ...mapActions('ram', [
      'FETCH_ALGOS',
    ]),
    ...mapMutations('edm', [
      'RESET_EMAIL_DESIGN',
    ]),
    ...mapMutations('flex', [
      'SET_TEST_EMAIL_RECIPIENTS',
      'RESET_FLEX',
      'SET_CUSTOMER_FIRST_NAME',
      'SET_CUSTOMER_LAST_NAME',
      'SET_EMAIL_DESIGN_ID',
      'SET_ALGO_ID',
      'SET_RELEASE_TYPE',
      'SET_CUSTOMER_EMAILS'
    ]),
    ...mapMutations('messages', [
      'CLEAR_MESSAGES',
    ]),
    clearForm (): void {
      this.RESET_FLEX()
      this.SET_TEST_EMAIL_RECIPIENTS('')
      this.RESET_EMAIL_DESIGN()
    },
    firstNameIsValid (): boolean {
      return this.firstName.trim() !== ''
    },
    lastNameIsValid (): boolean {
      return this.lastName.trim() !== ''
    },
    emailIsValid (): boolean {
      return this.emails.trim() !== ''
    },
    emailDesignIsValid (): boolean {
      return this.emailDesignId !== null
    },
    releaseTypeIsValid (): boolean {
      return Object.values(RELEASE_TYPE_OPTIONS).includes(this.releaseType)
    },
    algoIsValid (): boolean {
      if (this.GET_REQUIRES_REDEMPTION_REWARD_ALGO({ id: this.emailDesignId })) {
        return this.algoId !== null
      }
      return true
    },
    testEmailRecipientsIsValid (): boolean {
      return this.testEmailRecipients.trim() !== '' && this.testEmailRecipients.split(',').length <= 10
    },
    checkAndDisplaySendEmailErrors (): Array<{ message: string }> {
      const errors = []
      if (!this.firstNameIsValid() && !this.hideGuestName) {
        errors.push({ message: this.copy.errors.invalidFirstName })
      }
      if (!this.lastNameIsValid() && !this.hideGuestName) {
        errors.push({ message: this.copy.errors.invalidLastName })
      }
      if (!this.emailIsValid()) {
        errors.push({ message: this.copy.errors.invalidGuestEmail })
      }
      if (!this.emailDesignIsValid()) {
        errors.push({ message: this.copy.errors.invalidEmailDesign })
      }
      if (!this.algoIsValid()) {
        errors.push({ message: this.copy.errors.invalidAlgo })
      }
      if (!this.releaseTypeIsValid()) {
        errors.push({ message: this.copy.errors.invalidReleaseType })
      }
      if (errors.length > 0) {
        errors.forEach((error) => this.ADD_ERROR(error))
      }
      return errors
    },
    checkAndDisplaySendTestEmailErrors (): Array<{ message: string }> {
      const errors = []
      if (!this.testEmailRecipientsIsValid()) {
        errors.push({ message: this.copy.errors.invalidTestEmailRecipients })
      }
      if (errors.length > 0) {
        errors.forEach((error) => this.ADD_ERROR(error))
      }
      return errors.concat(this.checkAndDisplaySendEmailErrors())
    },
    async sendEmail (): Promise<void> {
      this.CLEAR_MESSAGES()
      const errors = this.checkAndDisplaySendEmailErrors()
      if (errors.length === 0) {
        // different endpoint for sending multiple emails
        if (this.emails.includes(',')) {
          await this.SEND_FLEX_EMAILS()
        } else {
          await this.SEND_FLEX_EMAIL()
        }
      }
    },
    async sendTestEmail (): Promise<void> {
      this.CLEAR_MESSAGES()
      const errors = this.checkAndDisplaySendTestEmailErrors()
      if (errors.length === 0) {
        await this.SEND_TEST_FLEX_EMAIL()
      }
    }
  }
}
